iframe {
    display: none !important;
}

@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Semibold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@import '../fonts/icomoon/style.css';

.main_loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.96);
}

.main_loader img,
.main_loader > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main_loader > svg {
    width: 250px;
}

.main_loader img {
    width: 100px;
    z-index: 999;
}

body {
    font-family: 'kohinoor', sans-serif;
    font-size: 16px;
    color: #747474;
}

.font_raleway {
    font-family: 'Raleway', sans-serif;
}

.font_raleway.w300 {
    font-weight: 300;
}

.main_filter::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.main_filter::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.main_filter::-webkit-scrollbar-thumb {
    background: #8E8E8E; 
}

.main_filter::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.ant-picker-input input::placeholder,
.ant-input-number-input::placeholder,
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6c757d;
    opacity: 1; /* Firefox */
}
  
.ant-picker-input input:-ms-input-placeholder,
.ant-input-number-input:-ms-input-placeholder,
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #6c757d;
}
 
.ant-picker-input input::-ms-input-placeholder,
.ant-input-number-input::-ms-input-placeholder,
::-ms-input-placeholder { /* Microsoft Edge */
    color: #6c757d;
}

.ant-picker-input input {
    font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #2B2B2B;
    font-weight: 600;
}

h1, .h1 {
    font-size: 2.5rem;
}
  
h2, .h2 {
    font-size: 2rem;
}
  
h3, .h3 {
    font-size: 1.75rem;
}
  
h4, .h4 {
    font-size: 1.5rem;
}
  
h5, .h5 {
    font-size: 1.25rem;
}
  
h6, .h6 {
    font-size: 1rem;
}
 
.modal-backdrop.show {
    opacity: .7;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
}

.carousel-indicators .active:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
}

.auth_modal .form-control {
    height: auto;
    min-height: 50px;
    font-size: 18px;
    color: #888888;
    background-color: #ECEDEE;
    border: 0;
    border-radius: 0;
}

.auth_modal .form-control:focus {
    color: #888888;
    background-color: #ECEDEE;
    box-shadow: none;
}

.btn {
    padding: 0.44rem .75rem;
}

.auth_modal .btn {
    padding: 0.75rem .75rem;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary {
    background-color: #842014;
    border-color: #842014;
    box-shadow: none;
}

.btn-primary:hover {
    background-color: #56130a;
    border-color: #56130a;
}

.btn-secondary {
    background-color: #F9A720;
    border-color: #F9A720;
}

.btn-secondary.focus, .btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle,
.btn-secondary:hover {
    background-color: #d6901c;
    border-color: #d6901c;
    box-shadow: none;
}

.btn-info {
    background-color: #888888;
    border-color: #888888;
}

.cm_link_red {
    color: #842014;
    font-weight: 600;
    cursor: pointer;
}

.cm_link {
    cursor: pointer;
    color: #2B2B2B;
}

.pointer {
    cursor: pointer;
}

.is-invalid {
    border: 1px solid #dc3545 !important;
}

.btn-outline-primary {
    color: #842014;
    border-color: #842014;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #842014;
    border-color: #842014;
    opacity: 1;
}

.btn-primary.focus, .btn-primary:focus,
.btn-outline-primary:hover,
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show>.btn-outline-dark.dropdown-toggle {
    background-color: #842014;
    border-color: #842014;
    color: #fff;
}

.card {
    border: 0;
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 15px;
    border-radius: 10px;
    position: relative;
}

.form-group {
    position: relative;
    margin-bottom: 30px;
}

.input_info {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 35px;
    height: 50px;
    background: transparent;
}

.input_info_pos_change .input_info {
    top: 33px;
}

.btn-block+.btn-block {
    margin-top: 0;
}

.auth_modal .form-group {
    margin-bottom: 1rem;
}

input.PhoneInputInput {
    border: 0;
    outline: none;
}

.ant-picker.ant-picker-range.form-control {
    min-height: 38px;
}

.cm_select__control {
    border: 0 !important;
    background: transparent !important;
    position: relative !important;
    top: -5px !important;
    box-shadow: none !important; 
}

.cm_select__value-container {
    flex-wrap: nowrap !important;
}

.ant-select-selector {
    border: 0 !important;
    height: 100% !important;
}

.ant-select.form-control {
    height: calc(1.5em + .75rem + 2px);
    padding: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
    color: #495057;
    opacity: 1;
}

.ant-input-number.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.ant-input-number-input {
    height: 38px;
}

.ant-empty-description {
    color: #842014;
    font-size: 16px;
    font-weight: 600;
}

.btn-outline-danger {
    color: #842014;
    border-color: #842014;
    border-radius: 0;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #842014;
    border-color: #842014;
}

.ant-picker-clear {
    display: none !important;
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.bg_light {
    background: #F7F7F7;
}


.btn-light {
    background-color: #d8d6d6;
    border-color: #d8d6d6;
}

header {
    min-height: 113px;
}

.teamsez_page .top_bar {
    background: #1c0369;
}

.top_bar {
    background: #842014;
    font-size: 13px;
    padding: 13px;
    line-height: 1;
    color: #fff;
}

.top_bar a {
    color: #fff;
}

.top_bar a:hover {
    text-decoration: none;
    color: #F9A720;
}

.top_bar_left a {
    margin-right: 18px;
}

.top_bar_left a i {
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
}

.header_drop {
    display: inline-block;
}

.header_drop button {
    font-size: 13px;
    padding: 0;
    margin: 0 !important;
    line-height: 1;
    border: 0;
}

.header_drop button:focus,
.header_drop button:hover,
.header_drop button {
    background: transparent !important;
}

.header_drop .dropdown-menu {
    border: 1px solid #D8D6D6;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    border-radius: 5px;
    top: 29px !important;
    left: auto !important;
    right: 0 !important;
    transform: none !important;
}

.header_drop .dropdown-item {
    color: #2B2B2B !important;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
}

.header_drop span.dropdown-item,
.header_drop .dropdown-item a {
    color: #2B2B2B !important;
    display: block;
    padding: 8px 15px;
}

.header_drop .dropdown-menu:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: white;
    right: 15px;
    top: -8px;
    transform: rotate(45deg);
    border-top: 1px solid #D8D6D6;
    border-left: 1px solid #D8D6D6;
}

.cm_header {
    background: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,0.05);
    padding: 15px;
}

.scroll_header .cm_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.cm_header ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cm_header ul li {
    display: inline-block;
    margin-left: 22px;
}

.cm_header ul li a {
    display: block;
    color: #2B2B2B;
    padding: 10px 5px;
    position: relative;
    font-weight: 500;
    transition: all 0.2s;
}

.cm_header ul li:last-child a {
    color: #842014;
}

.cm_header ul li a:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 3px;
    border-radius: 2px;
    background: #F9A720;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s;
}

.cm_header ul li a.active,
.cm_header ul li a:hover {
    text-decoration: none;
}

.cm_header ul li a.active:after,
.cm_header ul li a:hover:after {
    width: 30px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: inherit;
}

.gift_card_link {
    display: inline-block;
    margin-right: 25px;
}

.gift_card_link a {
    display: inline-block;
    margin: 0 10px;
}

.gift_card_link a i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}


.body_container {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-bottom: 50px !important;
}

.donation_container {
    background-image: 
                    linear-gradient(rgba(0, 0, 0, 0.7), 
                    rgba(0, 0, 0, 0.7)), 
                    url('./../img/donation-bg2.jpg');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
}

.clickable-link {
    cursor: pointer; /* Show hand cursor on hover */
  }
.main_banner {
    position: relative;
}

.main_banner .carousel img {
    width: 100%;
    height: calc(100vh - 114px);
    min-height: 650px;
    object-fit: cover;
}

.main_filter_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.main_filter {
    background: #842014;
    min-height: 560px;
    margin-top: 10px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
    position: relative;
    padding: 15px;
    color: #fff;
}

.main_banner .main_filter {
    max-height: calc(100vh - 160px);
    overflow: auto;
    background: rgba(132, 32, 20, 0.9);
}

.main_filter ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cat_wrap {
    display: inline-block;
    width: 46px;
    height: 46px;
    position: relative;
    margin: 3px;
}

.cat_wrap input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
    opacity: 0;  
}

.cat_bg {
    background: #9E2C21;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    line-height: 46px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.05);
}

.cat_wrap input:checked + .cat_bg {
    background: #F9A720;
}

.cat_bg img {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
}

.cm_radius_0 .btn,
.cm_radius_0 .form-control {
    border-radius: 0;
    border: 0;
    outline: none;
}

.cm_radius_0 .form-control:focus {
    box-shadow: none;
}

.form_control__control {
    border-radius: 0 !important;
    border: 0 !important;
}

.form_control__control--is-focused {
    box-shadow: none !important;
}

.form_control__menu {
    color: #000;
}

.form_control__indicator-separator {
    display: none;
}

.form_control__indicator {
    color: #3E3E3E !important;
}

.react-datepicker-wrapper {
    display: block;
}

.auto_scheduler {
    position: relative;
    padding-top: 20px;
}

.auto_scheduler .btn {
    position: absolute;
    right: 0;
    top: 0px;
}

.auth_modal .modal-body {
    padding: 0;
}

.auth_modal .modal-content {
    min-height: 572px;
}

.h_auto .modal-content {
    min-height: 1px !important;
}

.auth_modal img {
    width: 399px;
    border-radius: 4px 0 0 4px;
}

.auth_modal img.normal{
    height: 637px;
    object-fit: cover;
}

.cm_blck_bg:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background: black;
    top: 0;
    left: 0;
}

.cm_blck_bg .row{
    position: relative;
    z-index: 99;
    min-height: 570px;
}

.auth_modal h3 {
    font-weight: 500;
}

.modal_close {
    position: absolute;
    right: 12px;
    top: 10px;
    color: #2B2B2B;
    font-size: 20px;
    cursor: pointer;
    z-index: 999;
}
.max-h {
    max-height: 60vh;
}

.auth_modal form {
    width: 100%;
    padding: 20px;
}

.change_modal {
    position: absolute;
    bottom: 12px;
    width: 100%;
    text-align: center;
    color: #2B2B2B;
    margin: 0;
}

.otp_modal h3 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 20px;
}

.otp_inputs {
    width: 280px;
    margin: 30px auto;
}

.otp_inputs > div {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}

.otp_inputs input {
    width: 50px !important;
    height: 50px;
    font-size: 26px;
    outline: none;
    color: #842014;
    border: 1px solid #707070;
    border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.btn_anim {
    position: relative;
}

.btn_anim:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 8px;
    border-radius: 100%;
    border-bottom: 0px solid #F9A720;
}

.btn_anim.show_anim:after {
    animation: fillBorder 1s infinite linear alternate, fillRoted 1s infinite linear;
}

.uploaded__test {
    display: flex;
    line-height: 1;
    margin: 15px 0;
}

.uploaded__test p {
    margin: 0 !important;
    font-size: 14px;
    margin-right: 40px !important;
}

.uploaded__test p svg {
    width: 17px;
    margin-left: 5px;
}

@keyframes fillBorder { 
    0% {
        border-bottom: 0px solid #F9A720;
    }
    100% {
        border-bottom: 30px solid #F9A720;
    }
}
@keyframes fillRoted { 
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.error_msg {
    text-align: center;
    color: red;
    margin-top: 20px;
}

.check_box_wrapper {
    position: relative;
    padding-left: 30px;
    color: #2B2B2B;
}

.check_box > span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
}

.check_box.checkbox_dark > span {
    border: 2px solid #000;
}

.check_box_wrapper input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
}

.check_box > span:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 12px;
    border-right: 3px solid #842014;
    border-bottom: 3px solid #842014;
    transform: rotate(35deg);
    top: 1px;
    left: 4px;
    opacity: 0;
}

.check_box_wrapper input:checked + .check_box > span {
    background: #fff;
}

.check_box_wrapper input:checked + .check_box > span:after {
    opacity: 1;
}

.cm_dark_check .check_box > span {
    border: 2px solid #2B2B2B;
    top: 3px;
}

.check_box_wrapper.cm_dark_check input:checked + .check_box > span {
    background: #842014;
    border-color: #842014;
}

.cm_dark_check .check_box > span:after {
    border-right: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
}

.cristal_bg:after,
.cristal_bg:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 380px;
    left: 0;
    top: 0;
}

.cristal_bg:after {
    background-image: url('../img/cristal-bg.png'), url('../img/cristal-bg.png');
    background-repeat: no-repeat;
    background-position: left, right;
    opacity: 0.1;
}
.cristal_bg:before {
    background-color: #F9A720;
}

.cristal_bg .container {
    position: relative;
    z-index: 99;
}

.camp_type_carousel_wrapper {
    padding-top: 70px;
    padding-bottom: 40px;
}

.full_img_card {
    position: relative;
    padding: 0 !important;
    padding-right: 40px !important;
}

.card_content {
    position: absolute;
    left: 15px;
    bottom: 0;
    color: #fff;
}

.card_content h3 {
    color: #fff;
    padding-right: 40px;
    font-size: 24px;
}

.camp_type_carousel_wrapper .slick-slider {
    padding: 20px 0;
}

.camp_type_carousel_wrapper .slick-next,
.camp_type_carousel_wrapper .slick-prev {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -50px;
    transform: translateX(-50%);
    border: 2px solid #F9A720;
    border-radius: 100%;
}

.camp_type_carousel_wrapper .slick-next:hover,
.camp_type_carousel_wrapper .slick-prev:hover {
    background: #F9A720;
}

.camp_type_carousel_wrapper .slick-prev {
    left: calc(100vw - 90px);
}

.camp_type_carousel_wrapper .slick-next {
    left: calc(100vw - 40px);
}

.camp_type_carousel_wrapper .slick-next:before,
.camp_type_carousel_wrapper .slick-prev:before {
    content: '';
    position: absolute;
    left: 11px;
    top: 12px;
    width: 13px;
    height: 13px;
    opacity: 1;
    transform: rotate(45deg);
}

.camp_type_carousel_wrapper .slick-next:before {
    border-top: 3px solid #f9a720;
    border-right: 3px solid #f9a720;
}
.camp_type_carousel_wrapper .slick-prev:before {
    border-bottom: 3px solid #f9a720;
    border-left: 3px solid #f9a720;
}

.camp_type_carousel_wrapper .slick-next:hover:before,
.camp_type_carousel_wrapper .slick-prev:hover:before {
    border-color: #fff;
}

.full_img_card img {
    width: 100%;
    max-height: 370px;
    object-fit: cover;
    object-position: top;
    min-height: 370px;
    background: #842014;
}

.section_title_center,
.section_title_left {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.section_title_center {
    text-align: center;
}

.section_title_center:after {
    content: '';
    width: 120px;
    height: 3px;
    background: #F9A720;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 2px;
}

.cm_color_white {
    color: #fff !important;
}

.cm_color_white:after {
    background: #842014;
}

.section_title_left:after {
    content: '';
    width: 120px;
    height: 3px;
    background: #F9A720;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 2px;
}

.cm_card {
    padding: 0 20px;
}

.card_img_wrap img {
    width: 100%;
    height: 230px;
    object-fit: cover;
}

.card_img_wrap {
    position: relative;
    height: 230px;
}

.card_img_wrap:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    background: transparent;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.5) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,transparent 25%,transparent 75%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,transparent 25%,transparent 75%,rgba(0,0,0,0.5) 100%);
}

.card_img_wrap > * {
    z-index: 99;
}

.card_img_wrap h4 {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 20px;
    color: #fff;
    padding-right: 55px;
    word-break: break-word;
}

span.cm_Ad {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
}

.camp_card_name {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    color: #fff;
    padding: 10px;
}

.camp_card_name h3 {
    font-size: 18px;
    color: #fff;
    margin: 0;
    font-weight: 500;
}

.camp_card_name h2 {
    font-size: 25px;
    display: inline-block;
    color: #fff;
    margin: 0;
    margin-left: 5px;
    vertical-align: middle;
}

.card_body {
    border: 1px solid #D0D0D0;
    padding: 10px;
    background: #fff;
}

.cm_card p {
    color: #2B2B2B;
    margin-bottom: 10px;
}
.full_img_card p {
    color: #fff !important;
    margin-bottom: 15px !important;
}

.cm_card p {
    color: #2B2B2B;
    margin-bottom: 10px;
}

.EZPro_rating {
    display: inline-block;
    font-size: 14px;
    background: #F9A720;
    padding: 4px 10px;
    border-radius: 3px;
    color: #fff;
}

.EZPro_rating i {
    font-size: 11px;
}

.color-primary {
    color: #842014 !important;
}

.starting_soon_card {
    position: relative;
    padding: 0 !important;
    padding-right: 40px !important;
}

.starting_soon_card > a {
    position: relative;
    display: block;
}

.starting_soon_card img {
    width: 100% !important;
    height: 300px;
    object-fit: cover;
}

.starting_soon_card a > h3 {
    color: #fff;
    font-size: 18px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.cm_not_slider {
    display: inline-block;
    width: calc(100% / 6 - 20px);
}

.cm_content {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(132, 32, 20, 0.85);
    color: #fff;
    padding: 10px;
    padding-right: 40px;
    width: 100%;
}

.cm_content h3 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.cm_content .cm_price {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}

.section_header {
    padding: 15px 0;
    border-bottom: 1px solid #b4b4b4;
    margin: 15px 0;
    margin-bottom: 30px;
}

.no_bg__control--is-focused,
.no_bg__control.hover,
.no_bg__control {
    border: 0 !important;
    box-shadow: none !important;
}

.no_bg__indicator-separator {
    display: none !important;
}

.no_bg__indicator {
    padding: 0 !important;
}

.no_bg__input input,
.no_bg__single-value,
.no_bg__placeholder {
    position: static !important;
    transform: none !important;
    font-size: 28px !important;
    font-weight: 600;
    color: #2B2B2B !important;
}

.no_bg__indicator svg {
    display: inline-block;
    fill: #2B2B2B;
    line-height: 1;
    stroke: #2B2B2B;
    stroke-width: 0;
    width: 35px;
    height: 35px;
}

.camps_list {
    margin: 20px 0;
}

.camp_card > img {
    width: 100%;
    height: 420px;
    object-fit: cover;
}

.camp_card {
    position: relative;
    margin-bottom: 30px;
    min-height: 420px;
}

.camp_card_content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(132, 32, 20, 0.9);
    color: #fff;
    padding: 15px;
    z-index: 9;
}

.camp_card_content h3 {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
}

.camp_card_content h3 a {
    color: #fff;
    text-transform: capitalize;
}

.camp_card_content h3 a:hover {
    color: #f9a720;
}

.camp_card_content p {
    margin-bottom: 5px;
}

.camp_card_head {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    padding: 15px;
    z-index: 9;
    width: 100%;
}

.btn_like {
    position: absolute;
    right: 15px;
    top: 15px;
    background: rgba(0, 0, 0, 0.5);
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 39px;
    cursor: pointer;
    border-radius: 100%;
    z-index: 999;
}

.btn_like.active,
.btn_like:hover {
    background: #842014;
}

.camp_card_head h4 {
    color: #fff;
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
    padding: 5px 10px;
}

.cat_wrap_wrapper h3 {
    color: #fff;
}

.main_filter.cm_hide_cat {
    min-height: 20px;
    margin: 0;
    box-shadow: none;
    border-radius: 5px;
}

.main_filter .form-group {
    margin-bottom: 15px !important;
}

.side_silters {
    border: 1px solid #747474;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
}

.cm_clear_f {
    position: absolute;
    right: 15px;
    top: 14px;
}

.map_modal .cm_clear_f {
    top: 7px;
    font-size: 11px;
    padding: 4px 10px;
}

.side_silters h3 {
    padding: 15px;
    margin: 0;
}

.filter_box {
    border-top: 1px solid #747474;
    padding: 15px;
    position: relative;
}

.filter_box h4 {
    font-size: 20px;
    margin-bottom: 20px;
}

.filter_box ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.filter_box ul li {
    position: relative;
    margin-bottom: 10px;
}

.filter_box ul li .check_box > span {
    border: 2px solid #747474;
}

.items_val {
    position: absolute;
    right: 0;
    top: 0;
}

.SD_tag {
    position: absolute;
    padding: 10px;
    background: #abcf43;
    background: -moz-linear-gradient(left, #abcf43 0%, #51bf5a 100%);
    background: -webkit-linear-gradient(left, #abcf43 0%,#51bf5a 100%);
    background: linear-gradient(to right, #abcf43 0%,#51bf5a 100%);
    font-weight: 600;
    text-shadow: 2px 0px 0px rgba(0,0,0,0.16);
    border-radius: 0px 3px 3px 0;
    left: -12px;
    top: 12px;
    z-index: 9999;
    color: #fff;
}

.SD_tag:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid #51BF5A;
    border-left: 12px solid transparent;
    position: absolute;
    left: 0;
    bottom: -10px;
}

ul.pagination {
    justify-content: center;
}

ul.pagination li {
    border: 1px solid #8E8E8E;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 100%;
    margin: 0 4px;
    position: relative;
}

ul.pagination li.active,
ul.pagination li:hover {
    border: 1px solid #F9A720;
    background: #F9A720;
}

ul.pagination li a {
    color: #8E8E8E;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

ul.pagination li.active a,
ul.pagination li:hover a {
    color: #fff;
    text-decoration: none;
}

.cm_banner_wrapper {
    position: relative;
}

.cm_banner_wrapper img {
    height: calc(100vh - 115px);
    min-height: 600px;
    width: 100%;
    object-fit: cover;
}

.cm_banner_text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    z-index: 9;
}

.cm_banner_wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    opacity: 0.4;
}

.cm_partner_banner:after {
    opacity: 1;
    background: rgb(92,112,123);
    background: -moz-linear-gradient(left, rgba(92,112,123,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(left, rgba(92,112,123,1) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right, rgba(92,112,123,1) 0%,rgba(0,0,0,0) 100%);
}

.cm_banner_text h2 {
    font-size: 52px;
    color: #fff;
    margin-bottom: 25px;
}

.cm_banner_text p {
    font-size: 18px;
}

footer {
    background: #842014;
    color: #fff;
    font-size: 14px;
}

.teamsez_page footer {
    background: #1c0369;
}

.footer_middle {
    background: #fff;
    color: #2B2B2B;
    text-align: center;
    padding: 60px 0;
}

footer h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    margin-bottom: 7px;
}

footer ul li a:hover,
footer ul li a {
    color: #fff;
}

.cm_left_border {
    border-left: 2px solid #F9A720;
}

.apps_links img {
    height: 40px;
    margin: 0px 6px;
}

.color_red {
    color: #842014;
}
.color_black {
    color: #000 !important;
}

h4.color_red i {
    font-size: 42px;
    display: inline-block;
    vertical-align: middle;
}

footer p a {
    margin-right: 15px;
    color: #fff;
    font-size: 13px;
}

footer p a i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

footer .social_links a {
    margin-left: 15px;
    color: #fff;
}

footer a:hover {
    text-decoration: none;
    color: #F9A720 !important;
}

.tc_pp {
    padding: 50px 15px;
    font-size: 13px;
    line-height: 1.4;
}

.tc_pp .container {
    padding: 35px 15px;
    background: #ffffff;
    border-radius: 10px;
    min-height: 100vh;
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
}

.tc_pp h1 {font-size: 22px;}
.tc_pp h2 {font-size: 20px;}
.tc_pp h3 {font-size: 18px;}
.tc_pp h4 {font-size: 16px;}
.tc_pp h5 {font-size: 14px;}
.tc_pp h6 {font-size: 13px;}

.tc_pp_title {
    color: #842014;
    font-size: 22px !important;
}

.cm_section {
    position: relative;
    padding: 100px 0;
    overflow: hidden;
}

.cm_section {
    position: relative;
    padding: 100px 0;
}

.cm_counts {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 40px 20px;
    background: #fff;
    box-shadow: 0 3px 50px rgba(0,0,0,0.1);
    border-radius: 20px;
    margin-top: 50px;
}

.cm_counts li {
    display: inline-block;
    width: calc(100% / 4);
    text-align: center;
    border-right: 1px solid #B4B4B4;
}

.cm_counts li:last-child {
    border: 0;
}

.cm_counts li h3 {
    color: #842014;
    font-size: 32px;
    margin: 10px 0;
}

.cm_counts li h5 {
    color: #888888;
    font-size: 16px;
    margin: 0;
}

.cm_section:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: #FBA52C;
    border-radius: 100%;
    bottom: 0;
    left: -60px;
}

.about_img {
    position: absolute;
    right: -4%;
    top: 100px;
    height: calc(100% - 200px);
    border-radius: 100%;
}

.shadow_card {
    box-shadow: 0 3px 50px rgba(0,0,0,0.05);
    padding: 15px;
    padding-top: 50px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    background: #fff;
    height: 100%;
}

.shadow_card h5 {
    font-size: 18px;
    margin-bottom: 20px;
}

.card_num {
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight: 500;
    background: #842014;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #fff;
    line-height: 60px;
}

.cm_section.ball_right:before {
    left: auto;
    right: -60px;
}

.contact_bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contact_us_wrapper .header {
    background: #842014;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.contact_us_wrapper .header h2 {
    color: #fff;
    font-weight: 400;
    font-size: 24px;
    margin: 0;
}

.contact_us_wrapper .header h2 span {
    font-size: 30px;
    font-weight: 600;
    margin-left: 20px;
}

.contact_us_wrapper form {
    max-width: 630px;
    margin: 0 auto;
    padding: 100px 50px;
    width: 100%;
}

.cm_address_card {
    background: rgba(4, 33, 65, 0.5);
    position: absolute;
    padding: 15px;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
}

.cm_address_card p {
    margin: 0;
}

.contact_us_wrapper {
    padding-top: 100px;
}

.cm_section.ball_right {
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: cover;
}

.own_avtor {
    position: relative;
    padding-left: 75px;
    margin-bottom: 20px;
}

.own_avtor img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}

.own_avtor h3 {
    font-size: 16px;
    margin: 0;
    padding-top: 18px;
    padding-bottom: 18px;
}

.cm_list_wrapper h4 {
    font-size: 16px;
    margin: 0;
}

.search_wrap {
    position: relative;
    margin: 12px 0;
}

.search_wrap input {
    padding-left: 40px;
    background: #ECEDEE;
    border: 0;
}

.search_wrap i {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
}

.peoples ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.peoples ul li {
    position: relative;
    margin-bottom: 22px;
}

ul.cm_icon_space li {
    padding-right: 50px;
}

ul.cm_btn_space li {
    padding-right: 120px;
}

.peoples ul li i {
    position: absolute;
    right: 0;
    color: #F9A720;
    font-size: 28px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
}

.peoples ul li h5 {
    color: #842014;
    font-size: 16px;
    margin-bottom: 4px;
}

.peoples ul li p {
    margin: 0;
    color: #747474;
}

.peoples ul li .btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
}

.peoples .cm_icon_space {
    border-bottom: 1px solid #D0D0D0;
    margin-bottom: 30px;
}

.feed_card {
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
    font-size: 14px;
    background: #fff;
}

.feed_head {
    position: relative;
    padding-left: 75px;
    margin-bottom: 20px;
}

.feed_head img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}

.feed_head h3 {
    font-size: 18px;
    color: #3E3E3E;
}

.lcs_wrap {
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 0;
    font-size: 16px;
}

.lcs_wrap > span {
    cursor: pointer;
}

.feed_media {
    margin-bottom: 15px;
}
.feed_media img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.comment_msg,
.write_comment {
    position: relative;
    margin: 15px 0;
    padding-left: 65px;
}

.comment_msg img,
.write_comment img {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    border-radius: 100%;
}

.comment_option {
    position: absolute;
    right: 18px;
    top: 16px;
}

.comment_option i {
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px;
}

.emoji-mart.emoji-mart-light {
    position: relative;
    z-index: 9;
}

.comment_text {
    background: #F3F3F3;
    border-radius: 0 15px 15px 15px;
    padding: 15px;
}


.create_post_card {
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
}

.create_post_card h2 {
    background: #E0DFDF;
    padding: 15px 20px;
    margin: 0 -20px;
    margin-top: -20px;
    margin-bottom: 15px;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
    font-weight: 400;
}

.create_post_card h3 {
    color: #BEBEBE;
    font-weight: 400;
}

.modal_header {
    background: #842014;
    padding: 15px 20px;
    margin: 0 -16px;
    margin-top: -16px;
    margin-bottom: 15px;
    border-radius: 9px 9px 0 0;
}

.modal_header h3 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #fff;
}

.feed_modal .modal_close {
    color: #fff;
}

.off_bg {
    background: #842014;
    background: -moz-linear-gradient(45deg, #842014 0%, #f9a720 100%, #f9a720 100%);
    background: -webkit-linear-gradient(45deg, #842014 0%,#f9a720 100%,#f9a720 100%);
    background: linear-gradient(45deg, #842014 0%,#f9a720 100%,#f9a720 100%);
    margin-top: 20px;
    position: relative;
    color: #fff;
}

.off_bg img {
    max-width: 100%;
    position: relative;
    z-index: 9;
}

.off_bg:after {
    content: '';
    height: 30px;
    width: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
}
.off_bg h3 {
    color: #fff;
    font-size: 22px;
}

.off_bg h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.off_img {
    position: absolute !important;
    right: -60px;
    top: 0px;
}

.dash_card {
    padding: 20px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.11);
    border-radius: 10px;
    color: #000;
    border: 1px solid #D8D6D6;
}

.dash_card.h_225 {
    height: 225px;
}

.sm_chart_wrap {
    min-width: 180px;
    max-height: 150px;
}

.sm_chart_wrap canvas {
    max-height: 150px;
}

.doughnut {
    position: relative;
    width: 250px;
}

.dash_card ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dash_card ul li {
    position: relative;
    padding-left: 15px;
    word-break: break-word;
}

.dot_ {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: 0;
    top: 7px;
}

.xAxes {
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.yAxes {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    font-weight: 600;
}

.close_red {
    color: #842014;
    font-size: 24px;
    cursor: pointer;
}

.form_control__placeholder {
    white-space: nowrap;
}

.Mile_label {
    position: absolute;
    right: 9px;
    bottom: 10px;
}

.tc_pp_img {
    text-align: center;
    max-width: 100%;
}

.tc_pp_img {
    margin: 40px 0;
}

.map_modal .modal-body {
    padding: 0;
}

.map_modal .side_silters {
    margin: 0;
}

.map_view_wraper {
    position: relative;
    font-size: 13px;
    padding-left: 250px;
    overflow: hidden;
}

.map_view_filters {
    position: absolute;
    width: 250px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    background: #fff;
    padding: 10px;
    overflow: auto;
}

.map_view_wraper .filter_box h4 {
    font-size: 14px;
    margin-bottom: 10px;
}

.map_view_wraper .side_silters h3 {
    padding: 10px 15px;
    font-size: 18px;
}

.program_list {
    position: absolute;
    left: -30px;
    top: 0;
    z-index: 99;
    width: 280px;
    height: 100%;
    background: #F7F7F7;
}

.cm_open .program_list {
    left: 250px;
}

.program_list_inner {
    padding: 10px;
    height: 100%;
    overflow: auto;
    background: #f7f7f7;
    position: relative;
    z-index: 99;
}

.program_sm_card {
    border: 1px solid #B4B4B4;
    background: #fff;
    padding: 10px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-left: 100px;
}

.program_sm_card a {
    color: #747474;
}

.program_sm_card img {
    width: 80px;
    height: calc(100% - 20px);
    position: absolute;
    left: 10px;
    border-radius: 5px;
    object-fit: cover;
    top: 10px;
}

.program_sm_card h4 {
    font-size: 14px;
    margin-bottom: 5px;
    word-break: break-word;
}

.program_sm_card p {
    margin-bottom: 0;
}

.program_loader {
    height: 250px;
}

.program_loader svg {
    width: 100%;
    height: 100%;
}

.rate_wrap > div {
    position: absolute;
    left: 0;
    top: 0;
    background: #F9A720;
    border-radius: 3px;
    padding: 6px 10px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.rate_wrap {
    position: relative;
    padding-left: 48px;
    font-size: 12px;
}

.rate_wrap p {
    color: #000;
}

.program_sm_card p strong {
    color: #842014;
    font-size: 14px;
}

.toggle_prog_list {
    width: 30px;
    height: 30px;
    position: absolute;
    right: -30px;
    background: white;
    top: 10px;
    border-radius: 0 3px 3px 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    cursor: pointer;
}

.toggle_prog_list:before,
.toggle_prog_list:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 2px;
    background: #000;
    left: 7px;
    border-radius: 5px;
}

.toggle_prog_list:before {
    top: 9px;
    transform: rotate(45deg);
}

.toggle_prog_list:after {
    top: 17px;
    transform: rotate(-45deg);
}

.cm_open .toggle_prog_list:before {
    top: 9px;
    transform: rotate(-45deg);
}

.cm_open .toggle_prog_list:after {
    top: 17px;
    transform: rotate(45deg);
}

.fav_card .camp_card_name i,
.fav_card .card_img_wrap .icon-plus,
.fav_card .card_img_wrap .icon-check 
{
    position: absolute;
    right: 10px;
    border-radius: 100%;
    padding: 10px;
    z-index: 999;
    cursor: pointer;
}

.fav_card .card_img_wrap .icon-plus, .fav_card .card_img_wrap .icon-check {
    background: #842014;
    color: #fff;
    top: 10px;
}

.fav_card .camp_card_name i {
    background: #fff;
    color: #842014;
    bottom: 10px;
}

.fav_card .camp_card_name {
    padding: 15px 10px;
}

.fav_card .card_body h3 {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-right: 5px;
}

.fav_card .card_body h2 {
    display: inline-block;
    font-size: 23px;
    color: #842014;
    margin-left: 5px;
}

.pickup_modal_body h3 {
    font-size: 29px;
    font-weight: 400;
    line-height: 1.5;
}

.pickup_modal_body h4 {
    font-weight: 400;
    color: #747474;
    font-size: 16px;
    margin-top: 15px;
}

.pickup_modal_body img {
    width: 100px;
    height: 100px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    cursor: pointer;
}
.pickup_modal_body .active img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.pickup_modal_body .btn {
    min-width: 250px;
    border-radius: 0;
}

.pickup_modal_body .active h4 {
    font-weight: 500;
    color: #2B2B2B;
}

.pickup_user_wrapper {
    padding: 80px 0;
    max-width: 760px;
    margin: 0 auto;
    position: relative;
}

.pickup_user_wrapper .pickup_user_wrapper {
    padding: 0px !important;
}

h3.page_title {
    color: #842014;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 24px;
}

.pickup_user_wrapper h3 {
    text-align: center;
    margin-bottom: 35px;
}

.DraftEditor-root {
    border: 1px solid #cccccc;
    border-radius: 3px;
    min-height: 100px;
    padding: 0 10px;
    margin-top: 10px;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
}

.editer_btns {
    position: absolute;
    top: 0;
    right: 0;
}

.is__invalid .DraftEditor-root {
    border: 1px solid #dc3545;
}

.create_post_text {
    width: 100%;
    border: none;
    font-size: 14px;
}

.create_post_text:focus {
    outline: none;
    box-shadow: none;
}

.icon_upload_wrapper {
    width: 300px;
    height: 250px;
    background: #F1F1F1;
    border-radius: 10px;
    position: relative;
   margin: 20px auto;
}

.icon_upload_wrapper img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.icon_upload_wrapper > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.icon_upload_wrapper > div i {
    font-size: 46px;
    margin-bottom: 18px;
    color: #8E8E8E;
    display: inline-block;
}

.icon_upload_wrapper p {
    margin: 0;
}

.icon_upload_wrapper input {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
    left: 0;
    top: 0;
}

.cm_post_btn {
    border-radius: 0px;
    padding: 5px 25px;
    font-size: 12px;
}

.partner-form h3 {
    color: #842014;
    text-align: center;
    margin-bottom: 30px;
}

.partner-form .form-control {
    border: none;
    background-color: #ECEDEE;
    border-radius: 0px;
}

.partner-form button {
    border-radius: 0px;
    padding: 10px 35px;
    margin-top: 15px;
}

.partner-form .form-group {
    margin-bottom: 15px;
}

.advertising .img_wrap {
    width: fit-content;
    margin: 0 auto;
}

.text_wrapper .title{
    position: relative;
    color: #fff;
    text-align: left;
    padding-bottom: 5px;
    margin-bottom: 6px;
}

.text_wrapper .title:after {
    content: '';
    width: 120px;
    height: 1px;
    background: #F9A720;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 2px;
}

.advertising .cm_wrapper {
    padding-top: 50px;
}

.card_botom {
    margin-bottom: 50px;
}

.advertising *>.card_botom .text_wrapper {
    bottom: 0;
    top: unset;
    left: 0;
    width: unset ;
    transform: unset;
}

.camps_carousel_wrap {
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
}

.camps_carousel_wrap .slick-next,
.camps_carousel_wrap .slick-prev {
    width: 40px;
    height: 40px;
    border: 2px solid #842014;
    border-radius: 100%;
}

.camps_carousel_wrap .slick-next:hover,
.camps_carousel_wrap .slick-prev:hover {
    background: #842014;
}

.camps_carousel_wrap .slick-next:before,
.camps_carousel_wrap .slick-prev:before {
    content: '';
    position: absolute;
    left: 11px;
    top: 12px;
    width: 13px;
    height: 13px;
    opacity: 1;
    transform: rotate(45deg);
}

.camps_carousel_wrap .slick-prev {
    left: -50px;
}

.camps_carousel_wrap .slick-next {
    right: -50px;
}

.camps_carousel_wrap .slick-next:before {
    border-top: 3px solid #842014;
    border-right: 3px solid #842014;
}

.camps_carousel_wrap .slick-prev:before {
    border-bottom: 3px solid #842014;
    border-left: 3px solid #842014;
}

.camps_carousel_wrap .slick-next:hover:before,
.camps_carousel_wrap .slick-prev:hover:before {
    border-color: #fff;
}

.ellipse_wrapper {
    position: relative;
    width: 100px;
    line-height: 100px;
    height: 100px;
    border: 1px solid rgb(230,230,230);
    border-radius: 100%;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto
}

.ellipse_wrapper .icon {
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ellipse_wrapper img:first-child{
    height: 35px;
}

.ellipse_num {
    position: absolute;
    top: 0;
    left: 0;
    background: #842014;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    color: #fff;
}

.list_camp .cm_section {
    padding-top: 30px;
}

.pre-line {
    white-space: pre-line;
}

.white{
    color: #fff;
}

.career_card {
    border-radius: 0px;
    box-shadow: none;
}

.career_card h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0;
}

.card.career_card p {
    margin-bottom: 10px;
    font-size: 15px;
}

.career_card .text_wrapper {
    border: 1px solid black;
    border-top: none;
}

.career_footer_section{
    height: 250px;
}

.career_footer_section img {
    height: 250px !important;
}

.career_footer_section:after {
    opacity: 0.7;
}

.btn-white {
    background-color: #ffffff;
    color: #842014;
    font-weight: 500;
    padding: 10px 30px;
}

.btn_transp {
    font-size: 12px;
    border-color: rgb(69,68,69);
    padding: 10px 30px ;
}

.font_light{
    font-weight: 100;
}

.career_search {
    padding: 24px 10px;
    width: 86%;
}

.input_explore_btn{
    width: 14%;
}

.career .btn_wrapper {
    width: 85%;
    margin: 0 auto;
}

.big_search_wrap {
    position: relative;
}

.big_search_wrap i {
    position: absolute;
    left: 16px;
    top: 50%;
    color: #888888;
    font-size: 20px;
    transform: translateY(-50%);
}

.big_search_wrap input {
    padding-left: 50px;
}

.know_about_you_wrapper {
    position: relative;
}

.know_about_you_wrapper img {
    height: 600px;
    width: 100%;
    object-fit: cover
}

.know_about_you_wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    opacity: 0.7;
}

.list_camp_form_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    z-index: 9;
}

.know_about_you_wrapper .form-control {
    border: none;
    border-radius: 0px;
}

.know_about_you_wrapper .form-group {
    margin-bottom: 20px;
}

.how_it_works_section p {
    font-size: 12px;
    opacity: 1;
}

.how_it_works_section .row {
    padding: 50px 0;
}

.img_wrap {
    position: relative;
}

.img_wrap .text_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9;
    margin: 0 auto;
}

.img_wrap:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    opacity: 0.7;
}

.img_wrap .text_wrapper h3{
    color: #fff !important;
}

input.form-control.border_form_control {
    border: 1px solid #B4B4B4;
    background: #fff;
}

input.form-control.border_form_control:focus {
    background: #fff;
}

.header_drop .dropdown-toggle::after {
    border: 0;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    margin-left: 10px;
    position: relative;
    top: 2px;
}

p.is-invalid {
    position: relative;
    padding: 5px;
    margin: 0 -5px !important;
}

.is_invalid_msg {
    position: absolute;
    font-size: 12px;
    color: #dc3545;
    bottom: 0;
    right: 5px;
}

.main_filter .check_box_wrapper {
    color: #ffff;
}

.program_head {
    border-bottom: 1px solid #B4B4B4;
    margin-bottom: 30px;
    padding: 30px 0;
    padding-bottom: 5px;
}

.program_head h2{
    font-size: 26px;
    margin: 0;
}

.program_head h2 img {
    max-width: 175px;
    max-height: 75px;
    margin-right: 15px;
}

.ratings .icon-star {
    margin-left: 2px;
    color: #FFC107;
}

.ratings {
    color: #2B2B2B;
}

.program_page h3 {
    color: #842014;
    font-size: 22px;
    margin: 0;
}

.prog_media {
    min-height: 400px;
    position: relative;
}

.prog_slide_img img {
    /* height: 400px; */
    width: 100%;
    object-fit: cover;
}

.quantity button, .quantity span {
    display: inline-block;
    width: 26px;
    height: 26px;
    background: #D8D6D6;
    cursor: pointer;
    position: relative;
    border: 0;
    outline: none;
}

.quantity {
    margin-top: 12px;
}

.quantity > * {
    vertical-align: middle;
}

.quantity b {
    display: inline-block;
    line-height: 26px;
    height: 26px;
    padding: 0 12px;
    color: #2B2B2B;
    font-weight: 500;
}

.quantity .dec:after,
.quantity .inc:after,
.quantity .dec:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    background: #888888;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 2px;
}

.quantity .dec:before {
    width: 2px !important;
    height: 15px !important;
}

.quantity button:hover {
    background: #842014;
}

.quantity button:hover:after,
.quantity button:hover:before {
    background: #fff;
}

.prog_price h3 {
    color: #888888;
    font-weight: 500;
    font-size: 19px;
}

.prog_price h3 b {
    font-weight: 600;
    color: #842014;
    font-size: 24px;
}

.prog_price small {
    color: #48A7FC;
    font-weight: 500;
}

.daily_schedule {
    background: #F6F6F4;
    border-radius: 5px;
}


.daily_schedule {
    background: #F6F6F4;
    border-radius: 5px;
    padding: 20px;
    padding-right: 0;
}

.daily_schedule ul {
    margin: 0;
    list-style: none;
    padding: 20px 10px 20px 20px;
    border-left: 1px solid #E8E8E8;
}

.daily_schedule ul li {
    position: relative;
    margin-bottom: 20px;
}

.daily_schedule ul li b {
    display: block;
    color: #842014;
    font-weight: 500;
}

.daily_schedule ul li:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background: #ffc107;
    border-radius: 100%;
    left: -27px;
    top: 13px;
}

.program_detail .cm_bordered {
    border-left: 1px solid #842014;
    border-right: 1px solid #842014;
}

.program_detail p {
    margin: 15px 0;
}

.program_detail p b {
    color: #2B2B2B;
    font-weight: 500;
}

.adds_card {
    position: relative;
    display: inline-block;
}

img.adds_icon {
    position: absolute;
    right: 29px;
    top: 26px;
}

.adds_title {
    position: absolute;
    left: 45px;
    top: 38px;
    text-align: left;
}

.adds_title h4 {
    background: #F9A720;
    color: #fff;
    padding: 9px 15px;
    font-size: 16px;
    display: inline-block;
}

.cm_hide_cat .cm_gendr {
    display: block !important;
    margin-bottom: 10px;
}

.cm_hide_cat .cm_gendr label {
    display: block !important;
    width: 100%;
}

.day_wrap {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    margin: 4px;
}

.day_wrap input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
}

.day_bg {
    background: white;
    height: 100%;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    line-height: 36px;
    color: #6c757d;
}

.day_wrap input:checked + .day_bg {
    background: #F9A720;
    color: #fff;
}

.day_wrapper {
    text-align: center;
}

.prog_media .slick-arrow.slick-next,
.prog_media .slick-arrow.slick-prev {
    background: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    z-index: 99;
}

.prog_media .slick-arrow.slick-prev {
    left: 15px;
}

.prog_media .slick-arrow.slick-next {
    right: 15px;
}

.prog_media .slick-next:before,
.prog_media .slick-prev:before {
    content: '';
    width: 10px;
    height: 10px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    opacity: 1;
    display: inline-block;
    transform: rotate(45deg);
    position: absolute;
    left: 11px;
    top: 10px;
}

.prog_media .slick-next:before {
    transform: rotate(-135deg);
    left: 8px;
}

.no_img {
    background: #D8D6D6;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.no_img svg {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

button.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw.gm-style-iw-c {
    padding: 0 !important;
}

.gm-style-iw-d {
    max-height: none !important;
    overflow: unset !important;
}

.map_program_card {
    margin: 0 !important;
    border: 0 !important;
    min-width: 255px;
    line-height: 1.66667;
}

.cm_horizental_space {
    display: inline-block;
    width: 15px;
}

.program_sm_card .no_img {
    width: 80px;
    left: 10px;
    top: 10px;
    height: calc(100% - 20px);
    border-radius: 5px;
}

.program_sm_card .no_img svg {
    width: 50px;
    height: 50px;
}

.as_lock_wrap {
    position: absolute;
    left: -30px;
    top: 0;
    text-align: center;
}

.as_count {
    display: block;
    width: 25px;
    height: 25px;
    background: #F9A720;
    color: #fff;
    text-align: center;
    line-height: 25px;
    border-radius: 100%;
    font-size: 13px;
    margin-bottom: 10px;
}

.as_lock_wrap i {
    font-size: 25px;
    cursor: pointer;
}

.btn_remove {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #842014;
    background: #fff;
    font-size: 14px;
    padding: 5px 15px;
    cursor: pointer;
}

.cm_seemore {
    color: #842014;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.cm_spacial_price {
    top: -26px;
}

.cm_spacial_price h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
}

.under_red {
    position: relative;
    display: inline-block;
}


.cm_spacial_price .under_red:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: white;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.clear_filters {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    display: inline-block;
    cursor: pointer;
}

.clear_filters:hover {
    color: #f9a720;
}

.ant-select-item {
    color: #000;
    font-size: 14px;
    padding: 10px 15px;
}

.contact_us_wrapper h2 {
    position: relative;
    display: inline-block;
    padding-left: 50px;
}

.contact_us_wrapper .icon-phone-big {
    font-size: 37px;
    position: absolute;
    left: 0;
    top: 0;
}

.line_h_2,
.shadow_card p {
    line-height: 2.2;
}

.map_search_seraper {
    position: absolute;
    left: 300px;
    top: 9px;
    width: 260px;
    z-index: 99;
}

.cm_open .map_search_seraper {
    left: 580px;
}

.map_search_seraper input {
    border: 0;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.11);
    border-radius: 3px;
    padding-left: 38px;
}

.map_search_seraper i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
}

.ant-select-selection-placeholder {
    opacity: 1;
}

.anticon.anticon-swap-right {
    display: none !important;
}

.ant-picker-separator:after {
    content: '';
    width: 14px;
    height: 2px;
    background: #bfbfbf;
    position: absolute;
    top: 7px;
    border-radius: 4px;
    left: 0;
}

.camp_detail_map {
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
}

.camp_detail_address {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #747474;
    color: #fff;
    padding: 10px;
}

.starting_soon_card .no_img {
    min-height: 300px;
    position: static;
}

.associated_wrapper {
    background: #F9A720;
    margin: 50px 0;
    padding: 35px;
    padding-bottom: 10px !important;
}

.associated_sm_card {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    padding-left: 175px;
    min-height: 170px;
    margin-bottom: 30px;
}

.associated_wrapper {
    background: #F9A720;
    margin: 50px 0;
    padding: 35px;
}

.associated_sm_card img {
    position: absolute;
    width: 150px;
    left: 10px;
    top: 10px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.associated_sm_card h3 {
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 2px;
    
}


.associated_sm_card .max_2_line {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 45px;
}

.associated_wrapper h2 {
    font-size: 20px;
    color: #842014;
    margin-bottom: 20px;
    margin: 0;
}

.associated_sm_card p {
    margin: 0;
}

.associated_sm_card .icon-heart,
.associated_sm_card .icon-share-fill {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #842014;
    cursor: pointer;
}

.associated_sm_card .icon-heart-fill,
.associated_sm_card .icon-heart {
    right: 35px;
}

.associated_sm_card .icon-heart-fill {
    right: 35px !important;
    position: absolute;
    top: 11px;
}

.card_price {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #842014;
    font-weight: 600;
    font-size: 26px;
    line-height: 1;
}

.associated_wrapper h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px;
}

.other_detail ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.other_detail ul li {
    position: relative;
    margin-bottom: 30px;
    padding-left: 115px;
    min-height: 90px;
}

.other_detail ul li img {
    position: absolute;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #BEBEBE;
    border-radius: 5px;
    left: 0;
    top: 0;
}

.other_detail ul li h4 {
    font-size: 20px;
    color: #3E3E3E;
    margin: 0;
}

.other_detail ul li h6 {
    color: #888888;
    font-weight: 500;
    margin: 0;
}

.other_detail ul li p {
    margin: 0;
    font-size: 14px;
}

._border_right,
.cm_border_right {
    border-right: 1px solid #B4B4B4;
}

.row._border_bottom {
    border-bottom: 1px solid #B4B4B4;
}


.review_sm_card {
    border: 1px solid #D8D6D6;
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
    padding: 15px;
    padding-left: 90px;
}

.review_sm_card img {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 15px;
    top: 15px;
}

.review_sm_card p {
    margin: 0;
    font-size: 14px;
}

.other_detail .ratings {
    display: inline-block;
}

.other_detail h4 {
    font-size: 20px;
    margin-bottom: 15px;
}

.other_detail {
    margin-bottom: 40px;
}

.sm_section ul {
    margin: 15px 0 30px 0;
    padding-left: 20px;
    list-style: none;
}

.sm_section ul li {
    position: relative;
}

.sm_section ul li:before {
    content: '';
    width: 10px;
    height: 2px;
    background: #747474;
    left: -13px;
    top: 12px;
    position: absolute;
}

.rdrDefinedRangesWrapper{
    display:none;
}

.card_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
}

.card_loader svg {
    width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.blockout_dates {
    background: #fff;
    color: #747474;
    padding: 6px;
}

.blockout_dates_wrapper .icon-close {
    position: absolute;
    right: 10px;
    top: 45px;
    color: #000;
    cursor: pointer;
}

.blockout_dates_wrapper .icon-delete {
    display: inline-block;
    margin-right: 7px;
    font-size: 14px;
    cursor: pointer;
}

.card_link {
    position: absolute;
    width: calc(100% - 148px);
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    z-index: 99;
}

.cm_gendr label {
    margin-right: 10px;
}

.no_icon_cross span.ant-select-selection-item {
    display: none !important;
}

.field_clear {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #646a70;
    background: #ffffff;
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
}

.form-group.col-md-6 .field_clear {
    right: 16px;
}

.cm_more_space .field_clear {
    right: 49px;
}

.clear_checkboxs {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
    color: #646a70;
}

ul.cm_map_opt {
    position: absolute;
    background: white;
    width: 100%;
    top: 40px;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.11);
    border-radius: 0;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.cm_map_opt li {
    padding: 10px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
}

ul.cm_map_opt li:hover {
    background: #f9a720;
    color: #fff;
}

.rdrMonths,
.rdrCalendarWrapper{
    z-index:99999;
}

.cart_card {
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    position: relative;
    padding: 15px;
    padding-left: 180px;
    padding-right: 160px;
    margin-bottom: 30px;
    min-height: 180px;
}

.cart_card > a,  .cart_card > .no_img{
    position: absolute;
    width: 150px;
    height: 150px;
    object-fit: cover;
    left: 15px;
    top: 15px;
    border-radius:4px;
}

.cart_card > a img {
    max-width: 100%;
}

.cart_card h3 {
    font-size: 24px;
}

.cart_actions {
    position: absolute;
    right: 15px;
    top: 15px;
    height: calc(100% - 30px);
}

.cart_price {
    width: 100%;
}

.ant-menu-root {
    box-shadow: 0px 3px 20px rgba(0,0,0,0.05) !important;
    border-radius: 10px;
    border: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}

.ant-menu-root > li {
    font-size: 16px;
    color: #2B2B2B;
    font-weight: 500;
    margin-bottom: 15px !important;
}

.ant-menu-sub {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.ant-menu-item-only-child {
    font-size: 14px;
    color: #2B2B2B;
    font-weight: 400;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    color: #842014;
    font-weight: 500;
}

.ant-menu-item:hover {
    color: #842014;
    font-weight: 500;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
    display: none !important;
}

.booking_page h2 {
    font-size: 22px;
}

.card.booking_card {
    padding-left: 185px;
    margin-bottom: 20px;
}

.card.booking_card img {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 160px;
    height: calc(100% - 20px);
    object-fit: cover;
    border-radius: 5px;
}

.card.booking_card h3 {
    font-size: 20px;
    padding-right: 90px;
}

.card.booking_card p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 6px;
}

.booking_icon_btns {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #842014;
}

.booking_icon_btns a {
    color: #842014;
}

.booking_icon_btns i {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.cm_about_booking {
    position: relative;
    padding-right: 100px;
}

.booking_price {
    position: absolute;
    right: 0;
    bottom: 0;
}

.booking_price span {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    color: #842014;
}

.booking_btns {
    position: relative;
    padding-right: 210px;
    min-height: 50px;
}

.booking_date_numner {
    position: absolute;
    right: 0;
    top: 0;
}

.rewards_points {
    border-bottom: 1px solid #D0D0D0;
    margin: -15px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: 1;
}

.rewards_points span {
    color: #F9A720;
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    font-weight: 600;
}
.rewards_points {
    border-bottom: 1px solid #D0D0D0;
    margin: -15px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: 1;
}

.rewards_points span {
    color: #F9A720;
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    font-weight: 600;
}

.reward_item {
    position: relative;
    border-bottom: 1px dashed #D0D0D0;
    padding: 15px 0;
    margin-bottom: 15px;
    padding-left: 115px;
}

.reward_item img {
    width: 100px;
    height: 100px;
    left: 0;
    top: 15px;
    position: absolute;
    object-fit: cover;
    border-radius: 5px;
}

.reward_exp {
    position: absolute;
    right: 0;
    top: 15px;
    color: #BEBEBE;
}

.reward_item h3 {
    font-size: 18px;
}

.copy_code {
    display: inline-block;
    border: 1px dashed #BEBEBE;
    background: #F6F6F4;
    color: #888888;
    border-radius: 4px;
    padding: 3px 17px;
}

.copy_code i {
    color: #1DC8DE;
    display: inline-block;
    margin-left: 7px;
}

.reward_item p {
    color: #888888;
    margin-bottom: 11px;
}

.off_msg {
    background-size: 25px;
    background-repeat: repeat-y;
    background-position: calc(100% + 15px) 0%;
    border-radius: 5px 0 0 5px;
    color: #fff;
    padding: 15px;
    min-height: 177px;
}

.reedem_wrap {
    border: 1px dashed #D0D0D0;
    padding: 30px 15px;
    text-align: center;
    width: 240px;
    position: absolute;
    right: 15px;
    top: 15px;
    height: calc(100% - 30px);
}

.card.coupon_card {
    padding-right: 270px;
}

.reedem_wrap p {
    margin: 0;
}

.reedem_wrap h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 15px 0;
}

.off_msg h2 {
    color: #fff;
    font-size: 40px;
}
.cat_wrap_inner {
    display: inline-block;
    width: calc(100% / 6);
    margin-bottom: 5px;
    text-align: center;
}

.cm_locations {
    position: absolute;
    max-height: 400px;
    width: calc(100% - 30px);
    left: 15px;
    top: 78px;
    border-radius: 4px;
    overflow: auto;
    z-index: 999;
    background-color: #fff;
    color: #6c6b6b;
}

.cm_locations .suggestion-item {
    background: #fff;
    display: block;
    padding: 10px 15px;
    border: 1px solid #e4e4e4;
}

.cm_locations .suggestion-item--active {
    background: #80bdff;
    display: block;
    padding: 10px 15px;
    color: #fff;
    border: 1px solid #e4e4e4;
    cursor: pointer;
}

.cm_error_msg {
    position: absolute;
    right: 0;
    top: 3px;
    color: #fbb231;
    font-size: 12px;
}

.cm_disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.cat_title {
    font-size: 18px;
    color: #8c2c23;
    margin-bottom: 15px;
}

.cart_card h3 a{
    color:#000;
}

.profile_upload {
    width: 110px;
    height: 110px;
    margin: 25px auto;
    position: relative;
    border-radius: 100%;
    background: #ededed;
}

.profile_upload input {
    position: absolute;
    font-size: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
}

.profile_upload i {
    position: absolute;
    background: #E8E8E8;
    border: 1px solid #F7F7F7;
    color: #842014;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 27px;
    border-radius: 100%;
    right: 0;
    bottom: 0;
}

.profile_upload img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 100%;
}

.pro_wrapper h4 {
    margin: 15px 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 22px;
}

.pro_wrapper .form-control {
    background-color: #ECEDEE;
    border: 0;
    height: auto;
    min-height: 48px;
}

.pro_wrapper .form-control:focus {
    background-color: #ecedee;
    box-shadow: none;
}

.pro_wrapper_2 .check_box > span,
.pro_wrapper .check_box > span {
    border: 2px solid #000;
}

.cm_gendr ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.color_primary {
    color: #842014;
}

.bottom_border {
    border-bottom: 1px solid #D8D6D6;
    padding-bottom: 20px;
}

.pro_wrapper label {
    color: #000;
    font-weight: 500;
}

.pro_wrapper .ant-select-selector {
    background: #ecedee !important;
    height: 48px !important;
    padding: .375rem .75rem !important;
}

.pro_wrapper .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
}

.pro_wrapper .ant-select-arrow path {
    fill: #000;
}

.group_code {
    font-size: .875rem;
    display: inline-block;
    border: 1px solid #842014;
    padding: 0.22rem .75rem;
    margin-left: 15px;
}

.cm_online_tag {
    top: 75px;
}

.multiselectvaluesdiv {
    border-radius: 4px;
    cursor: pointer;
    padding-top: 10px;
    white-space: nowrap;
    overflow: auto;
    padding-left: 10px;
    background: #ECEDEE;
    border: 0;
    height: auto;
    min-height: 48px;
}

.multi_select_options {
    position: absolute;
    background: #fff;
    z-index: 9;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    border-radius: 4px;
    width: calc(100% - 30px);
    top: 73px;
    left: 15px;
    max-height: 250px;
    overflow: auto;
}

.multi_select_options ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.multi_select_options ul li {
    padding: 5px 15px;
}

.multi_select_options ul li label {
    display: inline-block;
    margin-left: 10px;
}

.multiselectvaluesdiv span {
    display: inline-block;
    background: #fff;
    margin: 0 3px;
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 4px;
}

.sub_cat_wrap {
    margin-top: 20px;
}

.sub_cat_wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sub_cat_wrap ul li {
    margin-top: 8px;
}

.cm_on_line {
    font-size: 20px;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    margin-bottom: 18px;
    text-align: center;
    background: #abcf43;
    background: linear-gradient(to right, #abcf43 0%,#51bf5a 100%);
    font-weight: 600;
    text-shadow: 2px 0px 0px rgba(0,0,0,0.16);
}

.last_dot span:last-child {
    display: none !important;
}

.additionalExp {
    border-bottom: 1px solid #B4B4B4;
    padding: 15px 0;
}

.additionalExp h5 {
    font-size: 18px;
    margin-bottom: 15px;
}

.additionalExp p {
    margin-bottom: 0;
}

.additionalExp p b {
    font-weight: 500;
    color: #000;
}

.price_detail_popover .dropdown-toggle {
    background: transparent;
    border: 0;
    padding: 0;
}

.price_detail_popover .dropdown-toggle small:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border-bottom: 2px solid #48A7FC;
    border-right: 2px solid #48A7FC;
    margin-left: 7px;
    position: relative;
    top: -2px;
}

.price_detail_popover > .dropdown-toggle:focus,
.price_detail_popover.show > .dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}

.price_detail_popover .dropdown-menu {
    padding: 15px;
    min-width: 290px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #D8D6D6;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.20);
    left: auto !important;
    transform: none !important;
    right: 0 !important;
    top: 30px !important;
    min-height: 208px;
}

.gt_total {
    margin: 0 -15px;
    padding: 15px;
    border-top: 1px solid #D8D6D6;
    margin-top: 10px;
}

.cal_inner_hieght {
    background: #fff;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.05);
    position: relative;
    border-radius: 10px;
}

.fc .fc-timegrid-slot {
    height: 4.5em !important;
}

.cal_card {
    border: 0 !important;
    border-left-width: 3px;
    border-radius: 0px 10px 10px 0;
}

.fc-day-today {
    background: #fff !important;
}

.fc-event-time {
    font-size: 12px !important;
    text-align: center;
}

.fc-event-title.fc-sticky {
    font-weight: 400;
    position: static !important;
    padding-left: 5px;
    word-break: break-word;
}

.fc-scrollgrid {
    border: 0 !important;
}

button.fc-today-button {
    display: none !important;
}

.fc-scrollgrid-section-header .fc-scroller-harness {
    background: #FBFBFC !important;
    padding: 20px 0;
    border-radius: 8px 8px 0 0;
}

.fc-col-header th {
    border: 0 !important;
}

.fc-col-header .fc-scrollgrid-sync-inner {
    background: #fbfbfc;
}

.fc-media-screen {
    position: relative;
    margin-top: 60px;
}

.fc-header-toolbar {
    position: absolute;
    top: -50px;
    width: 100%;
    margin-bottom: 0 !important;
    display: block !important;
}

.fc-header-toolbar .fc-toolbar-chunk {
    display: inline-block;
    vertical-align: middle;
}

.cm_cal_left .ant-picker {
    height: 0;
    font-size: 0;
    padding: 0;
    width: 0;
    opacity: 0;
}

.dat_calander {
    z-index: 1 !important;
}

.cm_cal_left {
    width: 280px;
}

.cm_cal_right {
    width: calc(100% - 311px);
    margin-left: 30px;
    min-height: 600px;
    position: relative;
}

.activity_btn {
    position: absolute;
    right: 0;
    top: 4px;
    border-radius: 0;
    z-index: 99;
}

.dat_calander .ant-picker-panel-container {
    box-shadow: 0px 5px 30px rgba(0,0,0,0.05);
    border-radius: 10px;
}

.dat_calander .ant-picker-header {
    border-bottom: 0;
}

.dat_calander .ant-picker-header-super-next-btn,
.dat_calander .ant-picker-header-super-prev-btn {
    display: none !important;
}

.dat_calander .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
    background: #F7F7F7 !important;
}

.dat_calander td.ant-picker-cell.ant-picker-cell-in-view {
    color: #969CA8 !important;
}

.dat_calander .ant-picker-week-panel-row-selected td, .ant-picker-week-panel-row-selected:hover td {
    background: #FFF6E6;
    color: #969CA8 !important;
}

.dat_calander .ant-picker-week-panel-row-selected .ant-picker-cell .ant-picker-cell-inner {
    background: #fff6e6 !important;
    color: #969CA8 !important;
}

.fc-toolbar-title {
    font-size: 21px !important;
    min-width: 255px;
}

.fc-button-primary {
    background: transparent !important;
    border: 0 !important;
    color: #747474 !important;
    padding: 0 !important;
}

.fc-button-primary:focus {
    box-shadow: none !important;
}

.fc .fc-scrollgrid-section > td {
    border-right: 0 !important;
}

.fc-scrollgrid-section-header .fc-scroller-harness > div {
    overflow: unset !important;
}

.dat_calander .ant-picker-cell.ant-picker-cell-week {
    display: none !important;
}

.dat_calander .ant-picker-content thead tr th:first-child {
    display: none !important;
}

.fc-scrollgrid-section-liquid > td {
    border: 0 !important;
}

.fc .fc-col-header-cell-cushion {
    display: block;
    width: 44px;
    margin: 0 auto;
    font-weight: 400;
}

.fc-day-today a.fc-col-header-cell-cushion {
    color: #842014;
    font-weight: 500;
}

.cm_err_msg {
    font-size: 14px;
    color: red;
    position: absolute;
    bottom: -10px;
}

.cm_phone_field {
    position: relative;
}

.cm_phone_field .us_flag {
    position: absolute;
    top: 12px;
    left: 12px;
}

.cm_phone_field .form-control {
    padding-left: 40px;
}

.cm_phone_field_2 .us_flag {
    top: 12px;
}

.date_heading {
    background: #EBEAEA;
    color: #2B2B2B;
    font-weight: 500;
    padding: 6px 15px;
    margin-bottom: 15px;
}

.c_bor_b {
    border-bottom: 1px solid #B4B4B4;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.c_bor_b b {
    display: inline-block;
    min-width: 100px;
    text-align: right;
    color: #000;
}

.c_bor_b h4 {
    font-size: 20px;
    color: #842014;
    font-weight: 500;
}

.attach_file {
    margin-bottom: 10px;
}

.attach_file > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.attach_file_btn {
    border: 1px solid #842014;
    width: 130px;
    text-align: center;
    padding: 5px 15px;
    position: relative;
    color: #842014;
}


.attach_file_btn input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
}

.campers_add_exp,
.camper_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.camper_list li {
    background: #ECEDEE;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px;
}

.camper_list li img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.camper_list li h3 {
    font-size: 22px;
    margin-bottom: 0;
}

.camper_list li p {
    margin: 0;
}

.campers_add_exp .check_box > span,
.camper_list .check_box > span {
    border: 2px solid #000;
}

.camper_list .check_box_wrapper {
    width: 20px;
    height: 20px;
}

.campers_add_exp li {
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    margin-bottom: 20px;
}

.campers_add_exp li p {
    margin-bottom: 10px;
}

.campers_add_exp .check_box_wrapper{
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
    position: absolute;
}

.gift_card {
    margin-bottom: 30px;
    border-radius: 10px;
    position: relative;
    color: #fff;
    transition: all 0.3s;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #842014;
}

.gift_card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 20px 10px -15px rgba(0,0,0,0.3);
}

.gift_card:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url('../img/cristal-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1;
}

.gift_card:before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    background: black;
    border-radius: 10px;
    opacity: 0.3;
    z-index: 99;
}

.gift_card .logo_min {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
}

.gift_card a {
    position: relative;
    display: block;
    z-index: 99;
    color: #fff;
    padding: 15px;
}

.gift_card p {
    padding-left: 65px;
}

.gift_card h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
}

.gift_card_price h2 {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: #fff;
    line-height: 1;
}

.gift_card_price {
    font-size: 20px;
    line-height: 1;
}

.dark_heading {
    font-size: 22px;
    font-weight: 500;
    color: #842014;
    margin-bottom: 40px;
}

.page_loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    top: 0;
    left: 0;
}

.page_loader > svg {
    position: absolute;
    width: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.prog_action {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 9;
}

.prog_action i {
    width: 40px;
    height: 40px;
    display: inline-block;
    background: #fff;
    border-radius: 100%;
    margin-left: 12px;
    text-align: center;
    line-height: 40px;
    color: #842014;
    cursor: pointer;
}

.cm_err_toltip .input_info {
    top: 27px;
    z-index: 99;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.cm_tag {
    display: inline-block;
    padding: 7px 15px;
    background: #e39189;
    margin: 7px;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
}

.cm_tag i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    cursor: pointer;
}

.add_cat_btn {
    position: absolute;
    right: 0;
    top: 34px;
    line-height: 1;
    padding: 10px;
    font-weight: 400;
    border-radius: 0px 3px 3px 0;
}

.fc_actions {
    position: absolute;
    left: 260px;
    top: 8px;
    z-index: 9;
}

.fc-button-group {
    display: none !important;
}

.fc_actions button {
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;
}

.cm_cal_left .adds_card {
    margin-top: 260px;
}

.cm_color_green {
    color: green;
    display: block;
    margin-bottom: 15px;
}

.p_detail_wrap,
.qr_code_wrap {
    border: 1px solid #D8D6D6;
    min-height: 120px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.05);
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 15px;
}

.qr_code_wrap {
    padding-bottom: 35px;
}

.cm_bor_right {
    border-right: 1px solid #842014;
}

.booking_success {
    text-align: center;
    padding: 100px 0;
}

.booking_success h2 {
    color: #842014;
    font-weight: 600;
    margin: 25px 0;
}

.booking_success p {
    font-size: 20px;
    max-width: 80%;
    margin: 0 auto;
}

.no_cart_item {
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 100px 15px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 40px;
}

.gal_card img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 30px;
}

.gall_popup .modal-dialog {
    max-width: 90%;
}

.gall_popup .modal-header {
    padding: 0;
    border-bottom: 0;
}

.gall_popup .modal-header .close {
    position: absolute;
    right: 16px;
    top: 11px;
    z-index: 99;
}

.gall_popup .modal-body {
    padding: 1rem 50px;
    text-align: center;
}

.gal_next,
.gal_prev {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #dadada;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    cursor: pointer;
}

.gal_prev {
    left: 10px;
}

.gal_next {
    right: 10px;
}

.gal_prev:after,
.gal_next:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-left: 3px solid #000;
    border-bottom: 3px solid #000;
    top: 8px;
}

.gal_prev:after {
    transform: rotate(45deg);
    left: 10px;
}

.gal_next:after {
    transform: rotate(-135deg);
    left: 6px;
}

.gall_popup img {
    height: calc(100vh - 90px);
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    user-select: none;
}

.gal_next:hover,
.gal_prev:hover {
    background: #842014;
}

.gal_next:hover:after,
.gal_prev:hover:after {
    border-color: #fff;
}

.cart_remove_camp{
    color: skyblue;
    font-size: 14px;
    cursor: pointer;
}

.camper_list img {
    cursor:pointer;
}

.qr_img img {
    width: 100%;
}

.qr_img h5 {
    font-size: 16px;
    text-align: center;
    margin: 0;
    font-weight: 400;
}

.cm_follow {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    display: inline-block;
    background: #fff;
    border-radius: 100%;
    margin-left: 12px;
    text-align: center;
    line-height: 40px;
    color: #842014;
    cursor: pointer;
    z-index: 999;
}

.prog_action i.active {
    background: #842014;
    color: #fff;
}

.btnLoader {
    position: relative;
    color: transparent;
}

.btnLoader:after {
    content: '';
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #ffc107;
    position: absolute;
    right: 10px;
    top: 0;
    border-radius: 20px;
    margin: 0;
    animation: fillRoted 1s infinite linear;
    box-sizing: initial;
}

.cm_err_msg2 {
    position: absolute;
    right: 15px;
    font-size: 12px;
    color: red;
}

.cm_success_msg {
    text-align: right;
    color: green;
    margin-top: 10px;
}

.associated_sm_card .no_img {
    width: 150px;
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    border-radius: 10px;
}

.associated_P_link {
    position: absolute;
    width: calc(100% - 60px);
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    z-index: 9;
}

.cm_d_btn {
    border: 1px solid;
    padding: 4px 10px;
    border-radius: 4px;
    margin-left: 10px;
}

.suc_msg {
    color: green;
    font-size: 14px;
}

.post_upload_wrapper {
    background: #F1F1F1;
    border-radius: 10px;
    position: relative;
    margin: 20px auto;
    min-height: 300px;
}

.post_upload_wrapper.w-100 input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
}

.post_up_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.post_upload_wrapper img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    height: 300px;
}

.feed_m_item {
    width: 100%;
    position: relative;
    padding: 1px;
}

.more_then .feed_m_item {
    width: calc(100% / 2);
    display: inline-block;
    vertical-align: text-top;
}

.feed_media .feed_m_item {
    cursor: pointer;
}

.cm_upload_err {
    display: block;
    text-align: center;
    color: red;
    font-size: 14px;
}

.feed_m_item i {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
    font-size: 21px;
    cursor: pointer;
    opacity: 0.7;
    z-index: 99;
}

.feed_m_item video {
    width: 100%;
    height: 300px;
    display: inline-block;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 5px;
}

.cm_post_media input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
    font-size: 0px;
}

.cm_post_media i {
    font-size: 25px;
}

.cm_post_media {
    position: relative;
}

.feed_m_item h2 {
    height: 300px;
    background: #c3c3c3;
    vertical-align: revert;
    text-align: center;
    line-height: 300px;
    font-size: 90px;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
}

.cm_err_msg.on_feed {
    right: 1px;
    bottom: 20px;
}

.upload_loader {
    position: absolute;
    width: 70px;
    right: 150px;
    bottom: 8px;
}

.upload_loader span {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #842014;
}

.sticky_wrapper {
    position: relative;
}

.col_lg_8 {
    display: inline-block;
    width: 66%;
    padding-right: 15px;
}


.cm_stiky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 34%;
    display: inline-block;
    vertical-align: top;
    padding-left: 15px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    margin-bottom: 30px;
}

.no_more_data {
    background: white;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 20px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #868686;
}

.cm_over_auto {
    overflow: unset;
}

.scroll_header .cm_stiky {
    top: 90px;
}

.feed_content {
    word-break: break-word;
}

.cm_liked {
    color: #842014;
    font-weight: 500;
}

.view_more {
    color: #842014;
    cursor: pointer;
}

.cm_comment_text {
    background: #F3F3F3;
    border-radius: 25px;
    resize: none;
    font-size: 14px;
    padding: 24px;
}

.comment_text time {
    position: absolute;
    right: 40px;
    top: 10px;
}

.comment_text h6 {
    padding-right: 134px;
    word-break: break-word;
}

.comment_text p {
    margin: 0;
}

.cm_r_loader {
    position: relative;
}

.cm_r_loader svg {
    position: absolute;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
}

.like_loader {
    display: inline-block;
    position: relative;
    min-width: 51px;
}

.like_loader svg {
    position: absolute;
    width: 50px;
    left: 0;
    top: -13px;
}

.cm_top_info_space .input_info {
    top: 30px;
}

.cm_follow.active {
    background: #842014;
    color: #fff;
}

.peoples.users_list ul li {
    display: flex;
    align-items: center;
    padding-right: 100px;
}

.peoples.users_list ul li img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 10px;
}

.theme_modal .modal-content {
    border-radius: 11px;
}

.theme_modal .modal_close {
    color: #ffffff;
}

.theme_modal .search_wrap {
    margin: -15px -16px;
    margin-bottom: 15px;
}

.theme_modal .users_list {
    max-height: 365px;
    min-height: 365px;
    overflow: auto;
    padding-right: 15px;
}

.theme_modal .peoples .cm_icon_space {
    border-bottom: 0;
    margin-bottom: 0;
}

.theme_modal .search_wrap input {
    border-radius: 0;
    min-height: 50px;
}

.ant-collapse {
    margin-bottom: 50px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: rgb(132 32 20);
    font-weight: 500;
    font-size: 14px;
}

.ant-collapse-content-box {
    font-size: 14px;
    padding-left: 40px;
}

.pdf_icon svg {
    width: 25px;
    margin-top: 10px;
    margin-right: 30px;
}

.pdf_icon i {
    display: inline-block;
    position: relative;
    top: 7px;
}
.agegroup-warning{    font-size: 11px;
    text-align: left;
    margin-left: 14px;}

.container.cart_staps p {position: relative;padding-left: 61px;}

.container.cart_staps p span {
    position: absolute;
    left: 0;
    top: 0;
    color: #842014;
    font-size: 17px;
    font-weight: 500;
}

.price_detail_popover span {
    color: #888888;
    font-weight: 400;
}


.gt_total {
    color: #3E3E3E;
    font-size: 16px;
}

.ant-picker-cell-in-view .acive_cell:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: green;
    border-radius: 20px;
    right: 5px;
    top: 0;
}

.acive_cell.acive_program_dates:after {
    background: #fff;
}

.acive_cell {
    position: relative;
}

.user_info img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.user_info {
    position: relative;
    padding-left: 170px;
    min-height: 170px;
}

.campers_list {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #f7f7f7;
    border-radius: 5px;
}

.campers_list > li {
    display: flex;
    justify-content: space-between;
    background: #f7f7f7;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
}

.campers_list li img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
}

.campers_list li p {
    margin: 0;
    word-break: break-word;
}

.campers_list li a, ul.campers_list i {
    color: #000;
    margin-left: 13px;
}

.favloder_wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
}

.favloder_wrapper .card_loader svg {
    width: 250px;
}
.min_loader {
    position: absolute;
    right: 0;
    top: -4px;
    width: 95px;
    background: white;
    height: 39px;
    text-align: center;
}

.min_loader svg {
    width: 40px;
}

.cm_radio_wrap {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    padding-left: 30px;
    font-weight: 500;
    color: #000;
}

.cm_radio_wrap input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
}

.cm_radio_wrap span {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
    border: 1px solid #888888;
    border-radius: 100%;
}

.cm_radio_wrap input:checked + span {
    background: #F9A720;
    border-color: #F9A720;
}

.cm_radio_wrap input:checked + span:after {
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    border-radius: 100%;
    left: 4px;
    top: 4px;
}

.card_inputs {
    max-width: 350px;
}

.add_card_form .input_info {
    top: 27px;
}

.card_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.card_list li {
    padding: 15px 20px;
    margin-bottom: 20px;
    background: #f3f3f3;
    border-radius: 10px;
    position: relative;
    transform: translateY(0px);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
    transition: all 0.3s;
}

.card_list li.active {
    box-shadow: 0px 25px 25px -25px rgba(0,0,0,0.25);
    transform: translateY(-8px);
}

.p_card_img img {
    max-width: 70px;
    height: 55px;
    margin-right: 35px;
}

.card_list p {
    font-size: 18px;
    color: black;
    margin: 0;
}

.cm_cvc_input {
    width: 80px;
    margin-left: auto;
}

.card_list .check_box_wrapper {
    min-height: 23px;
}

span.cm_card_error {
    display: block;
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: right;
}

.cm_cvc_input .cm_card_error {
    position: absolute;
    white-space: nowrap;
    right: 20px;
    bottom: 0;
    margin-bottom: 0;
}

.page_card {
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    background: #fff;
    border-radius: 10px;
    color: #000;
    padding-bottom: 30px;
}

.page_card_header {
    border-bottom: 1px solid rgba(198, 198, 198, 0.50);
    padding: 15px 0;
    min-height: 61px;
    margin-bottom: 15px;
}

.page_card_body {
    padding: 15px;
    position: relative;
}

.chat_wrapper {
    height: calc(100vh - 205px);
    min-height: 500px;
}

.cht_users {
    width: 360px;
    border-right: 1px solid #e5e5e5;
    padding: 10px;
    height: 100%;
    overflow: auto;
    position: relative;
}

.chat_user_head {
    border-bottom: 1px solid #E5E5E5;
    width: 100% !important;
    padding: 10px;
}

.chat_msg img, .chat_user_head img, .cht_users ul .chat_user_img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.chat_user_head p {
    margin: 0;
    font-size: 14px;
}

.chat_screen {
    overflow: auto;
    position: relative;
}

.chat_msg {
    position: relative;
    margin: 40px 0;
    padding: 20px;
    padding-top: 40px !important;
}

.chat_msg img {
    position: absolute;
    left: 20px;
    top: 0;
}

.cm_name_m {
    position: absolute;
    left: 70px;
    top: 5px;
    font-weight: 400;
    color: #52575D;
}

.chat_msg.me_user .cm_name_m {
    left: auto;
    right: 20px;
}

.chat_msg .msg_text{
    padding: 12px 20px;
    margin: 0;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
    max-width: 75%;
    display: inline-block;
    position: relative; /* Important to handle child positioning */
    overflow: hidden; /* Prevents overflow of inner content */
    box-sizing: border-box; /* Includes border in the dimensions */
}

.msg_text p {
    margin: 0;
}

.chat_msg.other_user .msg_text {
    background: rgba(249, 167, 32, 0.5);
}

.chat_msg.me_user {
    text-align: right;
}

.contact_search {
    position: sticky;
    background: white;
    padding: 0 15px;
    margin: 0 -10px;
    margin-bottom: 10px;
    z-index: 9;
    top: -15px;
}

.contact_search i {
    position: absolute;
    left: 15px;
    top: 7px;
    color: #8E8E8E;
}

.contact_search input {
    border: 0;
    border-bottom: 2px solid #E5E5E5;
    padding: 0px;
    padding-left: 24px;
    width: 100%;
    font-size: 15px;
    padding-bottom: 7px;
}

.contact_search input:focus {
    outline: none;
    border-bottom: 2px solid #842014;
}

.cht_users ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cht_users ul li {
    position: relative;
    margin: 0 -10px;
    padding: 10px;
    border-bottom: 1px solid #f0eeee;
}

.cht_users ul p {
    margin: 0;
    font-size: 13px;
    color: #3E3E3E;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.chat_msg.me_user .msg_text {
    background: rgba(155, 155, 155, 0.1);
}

.chat_text_area {
    padding: 15px;
    position: relative;
}

.mt-auto.chat_text_area textarea {
    min-height: 50px !important;
    max-height: 170px !important;
    resize: none;
    border: 1px solid #E5E5E5;
    padding-top: 12px;
    border-radius: 40px;
}

span.send_btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #842014;
    cursor: pointer;
    font-size: 20px;
}

.fix_btn {
    border: 1px solid #2B2B2B;
    border-radius: 0;
    color: #2B2B2B;
    min-width: 130px;
}

.fix_btn.active {
    background: #842014 !important;
    border-color: #842014 !important;
}

.partner-form.search_wrap {
    max-width: 70%;
    margin: 25px auto;
}

.director_wrapper .btn {
    border-radius: 0;
}

.cm_swich_wrap {
    position: relative;
    height: 18px;
}

.cm_swich_wrap input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
}

.cm_swich_wrap > span {
    background: #8e8e8e;
    display: inline-block;
    width: 30px;
    height: 16px;
    border-radius: 14px;
    transition: all 0.3s;
    vertical-align: text-top;
}

.cm_swich_wrap input:checked + span {
    background: #3EE84B;
}

.cm_swich_wrap > span:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 100%;
    top: 4px;
    left: 3px;
    transition: all 0.3s;
}

.cm_swich_wrap input:checked + span:after {
    left: 16px;
}

.dir_camp_card {
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin: 30px 0;
    padding: 15px;
    position: relative;
}

.dir_camp_card img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    margin-right: 15px;
}

.dir_camp_card h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    word-break: break-word;
}

.dir_camp_card p {
    margin: 0;
    font-weight: 500;
    color: #888888;
    font-size: 14px;
}

.dir_booking_card {
    background: #eeeeee;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin: 8px 0;
    padding: 7px;
    position: relative;
}
.dir_booking_card p {
    margin: 0;
    font-weight: 500;
    color: #888888;
    font-size: 12px;
}
.dir_booking_card small {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    background-color: #841f15;
}
.dir_booking_card small:hover {
    background-color: #F9A720;
}

.cm_slots p {
    color: #842014;
}

.cm_slots {
    width: 90px;
    text-align: center;
}

.cm_slots h4 {
    color: #842014;
    margin: 0;
    font-weight: 500;
}

.remove_card {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.remove_card:hover {
    color: #842014;
}

.fav_card .card_img_wrap .btn_like{
    background: #842014;
    color: #fff;
    top: 59px;
    right: 10px;
}

.form-error{font-size:12px; color:#dc3545}
.campers_list .dropdown li {
    padding: 1px 10px;
}

.campers_list .dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    min-width: 250px;
}

.assoc_card .btn_like {top:10px!important; background:rgba(0, 0, 0, 0.5)!important}
.assoc_card .btn_like.active{background:#842014!important} 
.page_card_header.cm_revers {
    border-bottom: 0;
    border-top: 1px solid rgba(198, 198, 198, 0.50);
}

.counselor_card {
    background: #EBEAEA;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px 30px;
    position: relative;
    padding-left: 15px;
}

.counselor_card img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
}

.counselor_card h4 {
    font-size: 18px;
    color: #3E3E3E;
    font-weight: 500;
    word-break: break-word;
}

.counselor_card p {
    margin: 0;
    color: #2B2B2B;
    font-weight: 400;
}

.counselor_card .cm_swich_wrap {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.counselor_card .cm_swich_wrap > span:after {
    top: 3px;
}

.is_alert_ {
    border: 1px solid #f9a720;
    box-shadow: 0px 0px 7px #f9a720d4;
}

.cm_activity_card {
    font-size: 14px;
    display: block;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 11%);
    color: #747474;
}

.cm_activity_card:hover {
    background: #f7f7f7;
}

.cm_activity_card p {
    margin: 0;
}

.form-control.CustomSelect > div {
    background: #ecedee;
    border: 0;
    box-shadow: none;
}

.form-control.CustomSelect .css-1rhbuit-multiValue {
    background-color: #d1d1d1;
}

.all_counselorDetails {
    font-size: 14px;
    margin-top: 20px;
}

.all_counselorDetails p {
    display: flex;
    margin-bottom: 20px;
}

.all_counselorDetails p b {
    min-width: 270px;
}

.cm__chip,
.all_counselorDetails p span {
    background: #d0d0d0;
    padding: 8px 15px;
    line-height: 1;
    font-size: 13px;
    border-radius: 21px;
    color: #000;
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
}

.section_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    z-index: 99;
    border-radius: 20px;
}

.section_loader svg {
    position: absolute;
    width: 170px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.remove_counselor {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #2B2B2B;
    cursor: pointer;
}

.inline_ans {
    display: inline-block;
}

.inline_ans > div {
    display: inline-block;
    margin-left: 20px;
    position:relative
}

.cm_border_0 {
    border: 0 !important;
}

.res_error {
    background: #ffdada;
    color: #ff7272;
    padding: 10px;
    border: 1px solid #ffaaa0;
    font-weight: 400;
    font-size: 14px;
}

.friend_list .min_loader {
    position: relative;
}

.donate_sec {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../img/donation-bg2.jpg');
    padding: 120px 35px;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    background-position: center;
    position: relative;
}

.donate_sec h2 {
    color: #fff;
    font-size: 36px;
}

.btn_white {
    background: #fff;
    border-radius: 0;
    padding: 10px 30px;
}

.donate_sec:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    top: 0;
    left: 0;
}

.donate_sec .container {
    z-index: 9;
    position: relative;
}

.donate_wrap h3 {
    margin-bottom: 25px;
}

.donate_wrap h3 span {
    color: #842014;
}

.donate_wrap h4 {
    font-size: 18px;
}

.donate_wrap h4 span {
    color: #ffffff;
}

.donate_wrap .input_info {
    right: 15px;
    top: 27px;
}
.donation_form {
    background-color: rgba(229, 145, 32, 0.8);
    border-radius: 10px;
    padding: 20px;
    margin: auto;
  }
.cm_phone_field .input_info {
    top: -7px;
    right: 0;
}

.cht_users li {
    cursor: pointer;
}
.cht_users li.active {
    background: rgba(155, 155, 155, 0.1);
}

.equipment_row > span {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #842014;
    cursor: pointer;
}

.equipment_chip span {
    background: #842014;
    color: #fff;
    display: inline-block;
    padding: 5px 12px;
    font-size: 13px;
    border-radius: 15px;
    margin-right: 12px;
    margin-bottom: 8px;
    word-break: break-word;
}

.equipment_chip span i {
    cursor: pointer;
}

.folder_model {max-width: 450px;}
.folder_model .p-5{padding: 1rem 0rem !important;}
p.waring_text {
    color: #842014;
}

.edit-counselor .card {margin-bottom:15px}
.folder_card {
    position: relative;
    padding-left: 90px;
}

.folder_icon {
    position: absolute;
    width: 60px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.sel_card .check_box_wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    height: 26px;
}

.presnol-age-group .input_info { 
    left: 173px;
    top: -1px;
}

.list_camp_form .input_info {
    height: 38px;
    right: 16px;
}

.cabin_card .input_info {
    top: 33px;
    width: 40px;
    height: 40px;
}

.cabin_card .ant-select.form-control {
    padding-right: calc(1.5em + .75rem) !important;
}

.ant-select.form-control.is-invalid.pr-0 {
    padding-right: calc(1.5em + .75rem) !important;
}

.btn_sm {
    padding: .25rem .5rem !important;
    font-size: .875rem;
    line-height: 1.5;
}

.cm__card {
    box-shadow: 0px 3px 20px rgba(0,0,0,0.05) !important;
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 30px;
    position: relative;
    word-break: break-word;
    font-size: 14px;
}
.cm__card  .my_tooltip {
    position: absolute;
    width: 18px;
    right: 10px;
    top: 10px;
    height: 18px;
    display: inline-block;
    line-height: 1;
}

.cm_back_page {
    position: absolute;
    left: 15px;
    top: 10px;
    font-size: 14px;
    color: #842014;
    font-weight: bold;
}

.cm_back_page i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
}

.camper_detail img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 15px;
}

.camper_detail h4 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.aap_yellow {
    background: #F9A720;
    margin: 0 -15px;
    padding: 15px;
    color: #000;
}

.camper_detail .color_black {
    font-weight: 500;
}

.camper_card {
    background: #EBEAEA;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 20px;
    position: relative;
}

.cm_count {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    background: rgb(206, 206, 206, 0.6);
    width: 55px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 0 5px 0 0;
}

.camper_card img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
}

.link_color_black {
    color: #3E3E3E;
}

.add_prog_modal .modal-header {
    border: 0;
    display: block;
    text-align: center;
}

.add_prog_modal .modal-header .h4 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.cm_form_title {
    background: #E5E5E5;
    border-radius: 5px;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 15px;
    margin-bottom: 25px;
}

.input_space_form label {
    font-weight: 500;
    color: #000;
}

.all_actions {
    position: absolute;
    right: 15px;
    bottom: 25px;
}

.program_pdf {
    position: relative;
    display: inline-block;
}

.program_pdf input {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
}

.cm_cursor {
    cursor: pointer;
}

.all_actions i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
    font-size: 14px;
    cursor: pointer;
}

.cm_feild_err {
    text-align: center;
    color: #dc3545;
    font-size: 14px;
}

ul.program_imgs {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.program_imgs li {
    display: inline-block;
    border: 1px solid #8e8e8e;
    border-radius: 5px;
    margin-right: 15px;
    position: relative;
    margin-bottom: 15px;
}

ul.program_imgs li img {
    width: 160px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

ul.program_imgs.program_imgs_for_mob li img {
    width: 100px;
    height: 100px;
}

ul.program_imgs li i {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    background: #fff;
    padding: 2px;
    border-radius: 3px;
    padding-left: 3px;
}

.schedule_item {
    background: #F1F1F1;
    margin-bottom: 15px;
    position: relative;
    padding: 10px 15px;
    border-radius: 5px;
}

.schedule_item p {
    margin: 0;
    color: #2B2B2B;
    word-break: break-all;
}

.cm_action {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 18px;
    color: #2B2B2B;
}

.cm_action i {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.error_tootltip_label {
    position: absolute;
    width: 36px;
    height: 36px;
    background: transparent;
    right: 15px;
    top: 36px;
    cursor: pointer;
}

.no_img_2 {
    width: 125px;
    height: 125px;
    margin-right: 15px;
    background: #ECEDEE;
}

.remove_header_line .modal-header {
    border: 0 !important;
}

.remove_header_line .modal-title {
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.remove_header_line .modal-header {
    align-items: center;
    justify-content: center;
}

.end_date_label {
    display: inline-block;
    margin-left: 170px;
}

.icon-information {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.counselor_card h4 a {
    color: #3E3E3E;
}

.search_wrapper {
    background: #EBEAEA;
    margin: 0 -1rem;
    position: relative;
}

.search_wrapper input {
    border: 0;
    background: transparent;
    padding: 15px;
    width: 100%;
    padding-left: 45px;
    outline: none;
}

.search_wrapper i {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.user_list {
    max-height: 350px;
    overflow: auto;
    padding-right: 12px;
    margin: 15px 0;
}

.user_list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.user_list ul li {
    display: flex;
    align-items: center!important;
    border-bottom: 1px solid #D8D6D6;
    padding: 15px 0;
    cursor: pointer;
}

.user_list ul li .check_box_wrapper {
    margin-left: auto;
    height: 18px;
    margin-right: 0;
}

.user_list ul li img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.user_list ul li p {
    margin: 0;
}

.group_name input {
    border: 0;
    border-bottom: 1px solid #888888;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 100px;
    outline: none;
}

.user_list ul li:last-child {
    border: 0;
}

li.cm_empty {
    justify-content: center;
}

.downloads_btns {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 35px;
}

.downloads_btns .btn {
    margin-left: 10px;
}

.infos_icons i {
    display: inline-block;
    margin-left: 8px;
    line-height: 1;
    vertical-align: middle;
}

.infos_icons {
    line-height: 1;
    margin-bottom: 8px;
    position: relative;
}

.infos_icons span {
    color: #842014;
    font-weight: bold;
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
}

.chat_welcom_screen {
    width: 100%;
    position: relative;
}

.chat_welcom_inner {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chat_welcom_inner img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 40px;
}

.d_none,
.chat_box.d_none {
    display: none !important;
}

.msg_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 99;
}

.msg_loader svg {
    position: absolute;
    width: 130px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chat_screen.no_scroll {
    overflow: hidden !important;
}

.remain_user {
    position: absolute;
    right: 0;
    top: 0;
}

.feed_m_item .icon-play-button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 50px;
    font-size: 50px;
    color: #f9a720;
    opacity: 1;
}

.faq_action {
    text-align: right;
}

.faq_action button {
    margin-left: 20px;
    padding: 4px 15px;
    border-radius: 0;
}

.ant-picker-cell.ant-picker-cell-in-view .acive_program_dates {
    background: #f9a720;
    color: #fff !important;
    border-radius: 37px;
}

.normal_menu a {
    color: #2B2B2B !important;
}

.eye_wrapper .icon-eye {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 49px;
    text-align: center;
    height: 49px;
    line-height: 49px;
}

.eye_wrapper.with_label .icon-eye {
    top: 34px;
}

.eye_wrapper input {
    padding-right: 50px;
}

.eye_wrapper .icon-eye.slash:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 23px;
    background: #747474;
    border-radius: 4px;
    transform: rotate(45deg);
    left: 23px;
    top: 12px;
}

.feed_actions_counsloer {
    position: absolute;
    left: 94px;
    top: -19px;
}

.camper_actions_counsloer {
    position: relative;
}


.btn_back {
    position: absolute;
    top: 0;
    left: 0;
}

.create_f_title {
    font-size: 22px !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.campers_list i {
    cursor: pointer;
}

.cm_filter .dropdown-toggle:after {
    display: none;
}

.cm_filter .dropdown-menu {
    transform: none !important;
    right: 0 !important;
    top: 40px !important;
    left: auto !important;
    min-width: 320px;
    border: 0;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.16);
    border-radius: 5px 0 5px 5px;
}

.cm_filter .form-group {
    margin-bottom: 20px;
}

.cm_filter .form-group label {
    color: #000;
    font-weight: 500;
}

.cm_round_btn .btn {
    border-radius: 25px;
}

.review_sec {
    background: #f6f6f4;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 50px;
}

.review_sec textarea {
    max-width: 500px;
    margin: 30px auto;
}

.review_sec h6 {
    margin: 20px 0;
}

.advert_1 {
    width: 350px;
    height: 320px;
    object-fit: cover;
}

.campPersonell_profile .body_container{
    width:100%;
}

.without_overflow {
    overflow: unset !important;
}

.camp_list a {
    text-decoration: none;
}

.p_card {
    background: #F2F2F2;
    border-radius: 10px;
    border: 1px solid #D0D0D0;
    margin-bottom: 30px;
    text-align: center;
}

.p_card img {
    width: 100px;
    margin: 50px 0;
}

.p_card_body {
    background: #fff;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #D0D0D0;
    padding: 10px;
    text-align: left;
}

.p_card_body p {
    margin: 0;
}

.dark__ h3 {
    white-space: nowrap;
}

.dark__ .under_red:after {
    background: black;
}

.cabin_org_wrapper {
    background: #F7F7F7;
    padding-top: 40px;
}

.cabin_org_wrapper h3 {
    color: #842014;
    font-size: 24px;
    font-weight: 500;
}

.cabin_card {
    background: #fff;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.05);
    position: relative;
}

.cabin_users ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cabin_users ul li {
    background: #E8E8E8;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 95px;
}

.cabin_users ul li .btn {
    padding: 3px 10px;
    border-radius: 0;
    color: #fff;
}

.cabin_users .dropleft {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.cabin_users .dropdown-menu {
    border: 1px solid #D8D6D6;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    border-radius: 5px;
}

.post_delete_btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.scholarships_bg {
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    background-size: cover;
    z-index: 99;
}

.scholarships_bg > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
}

.scholarships_bg p {
    max-width: 65%;
    margin-top: 25px;
}

.modal_close a {
    color: #000;
}

.cabin_users {
    border-top: 1px solid #D0D0D0;
    margin: 20px -15px 0 -15px;
    padding: 20px 15px 0 15px;
    display: none;
}

.cabin_users.cm_expend {
    display: block;
}

.drop_chip > span {
    background: whitesmoke;
    display: inline-block;
    margin-right: 10px;
    padding: 2px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.drop_chip > span > span {
    display: inline-block;
    margin-right: 7px;
    margin-left: 7px;
}

.cm_card_link {
    color: #000;
}

.card.booking_card .no_img {
    left: 10px;
    top: 10px;
    width: 160px;
    height: calc(100% - 20px);
}

.dark__.cm_spacial_price h2 {
    color: #842117 !important;
}

.cm_cancellation ul li {
    margin-bottom: 10px;
}

.cm_cancellation .ant-input-number.form-control {
    display: inline-block;
    width: 75px;
    margin: 0 10px;
    padding: 0px;
    height: auto;
}

.cm_cancellation .ant-input-number.form-control .ant-input-number-input {
    height: 28px;
    text-align: center;
}

.border_box {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: inline-block;
    padding: 0 15px;
    min-width: 70px;
    text-align: center;
}

.cm_cancellation h3 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.cm_cancellation .check_box_wrapper {
    margin-bottom: 10px;
}

.three_dots {
    overflow: hidden;
    width:100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card_body.cm_min_height {
    min-height: 160px;
}

.cm_no_breck {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.news_detail img {
    width: auto;
    max-width: 400px;
}

.review_sec .ant-rate {
    background: #842014;
    padding: 6px 30px;
    border-radius: 36px;
}

.cabin_users li span {
    color: #842014;
    font-weight: bold;
    display: inline-block;
    margin-left: 6px;
    margin-right: 5px;
}
.cabin_users li i {
    display: inline-block;
    margin-right: 8px;
    line-height: 1;
    vertical-align: middle;
}

.view_rating li {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 1px !important;
}

.review_max_height {
    max-height: calc(100vh - 180px);
    overflow: auto;
    padding-right: 10px;
}

.share_options .dropdown-toggle {
    background: transparent;
    border: 0;
    color: #747474;
    padding: 0;
    outline: none;
    box-shadow: none !important;
}

.share_options .btn-primary:not(:disabled):not(.disabled):active,
.share_options .show>.btn-primary.dropdown-toggle,
.share_options .btn-primary {
    background: transparent !important;
    border: 0 !important;
    color: #747474 !important;
}

.share_options .dropdown-toggle::before,
.share_options .dropdown-toggle::after {
    display: none;
}

.share_wrapper {
    min-width: 220px;
}

.share_wrapper button {
    margin-left: 10px;
}

.associated_sm_card .share_options {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.booking_icon_btns .share_options.dropleft {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -4px;
}

.booking_icon_btns .icon-share-fill {
    color: #842014;
}

.prog_action .share_options {
    display: inline-block;
    vertical-align: bottom;
}

.camp_card_name .share_options {
    position: absolute;
    right: 0;
    bottom: 0;
}

ul.cancel_campers {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.cancel_campers li {
    border-bottom: 1px solid #e4e4e4;
    padding: 20px 0;
    position: relative;
}

ul.cancel_campers li p {
    margin-bottom: 0;
}

.dark_labal label {
    color: #000;
}

ul.cancel_campers li .btn {
    position: absolute;
    right: 0;
    top: 13px;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 21px !important;
}

.cancel_campers li:last-child {
    border-bottom: 0;
}

.spacial_price_Card h2 {
    font-size: 22px;
    display: block;
    color: #fff !important;
    margin: 0;
    margin-left: 5px;
    font-weight: 600;
    white-space: nowrap;
}

.spacial_price_Card .dark__ .under_red:after {
    background: #fff;
}

.comment_delete_btn {
    position: absolute;
    right: 12px;
    top: 14px;
    cursor: pointer;
}

.clone_icons {
    position: relative;
}

.clone_icon {
    position: absolute;
    bottom: -17px;
    left: 2px;
}

.selct_program {
    font-size: 15px;
    margin-bottom: 15px;
}

.selct_program .check_wrapper {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
}

.check_wrapper_2 {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
}

.selct_program .check_wrapper input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
}

.selct_program .check_wrapper > span {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 2px solid #000;
    border-radius: 2px;
}

.selct_program .check_wrapper > span:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 12px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(35deg);
    left: 5px;
    top: 1px;
    transition: all 0.25s;
    opacity: 0;
}

.selct_program .check_wrapper input:checked + span {
    background: #842014;
    border-color: #842014;
}

.selct_program .check_wrapper input:checked + span:after {
    opacity: 1;
}

.cm_auto_height {
    height: auto !important;
}

.cm_auto_height {
    height: auto !important;
    display: flex;
    align-items: center;
    border-radius: 0;
}

.cm_auto_height .ant-select-selector {
    min-height: 36px;
    width: 100%;
    border-radius: 0;
}

.cm_pdf_icon svg {
    width: 45px;
}

.cm_pdf_icon {
    margin: 20px 0;
    position: relative;
}

.cm_pdf_icon i {
    position: absolute;
    left: 35px;
    top: -10px;
    cursor: pointer;
}

.booking_users {
    margin: 0;
    padding: 0;
    word-break: break-word;
    list-style: none;
    margin-top:20px;
}

.booking_users li {
    border-bottom: 1px solid #d8d6d6;
    padding: 15px 0;
    position: relative;
}

.cm_upload_pdf {
    position: absolute;
    right: 0;
    top: 5px;
}

.booking_users h6 {
    padding-right: 21px;
}

.booking_users li p {
    margin: 0;
}

.booking_users li:last-child {
    border: 0;
}

.booking_users li b {
    color: #842014;
    font-weight: 500;
}

.cm_pdf_icon p {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
}

.info_wrap .icon-information {
    position: absolute;
    right: 45px;
    top: 16px;
}

.info_wrap input {
    padding-right: 75px;
}

.msg_count {
    position: absolute;
    right: 0;
    top: 50%;
    background: #f9a720;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: translateY(-50%);
}

.schedule_item p span,
.schedule_item h6 span {
    display: inline-block;
    vertical-align: text-top;
}

.schedule_item b {
    display: inline-block;
    min-width: 150px;
    font-weight: 400;
}

.cm_upload_pdf input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
}

.gardianPdf svg {
    width: 25px;
}

.gardianPdf {
    font-size: 13px;
    margin: 8px 0;
}

.gardianPdf a {
    display: block;
}

.cm_addit p {
    margin: 0;
}

.cm_addit {
    border: 1px solid #d8d6d6;
    padding: 15px;
    margin-bottom: 30px;
}

.confirmDetail .input_info {
    right: 15px;
    top: 34px;
    height: 38px;
}

.confirmDetail2 .input_info {
    right: 15px;
    top: 0px;
    height: 38px;
}

.confirmDetail .cm_phone_field .input_info {
    right: 0;
    top: 0;
    height: 38px;
}

.booking_users .upload_loader {
    right: 0;
    bottom: auto;
    top: -11px;
}

.chcek_icon {
    position: absolute;
    width: 19px;
    height: 19px;
    background: green;
    right: -30px;
    top: 0;
    border-radius: 100%;
}

.chcek_icon:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 8px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-50deg);
    left: 3px;
    top: 4px;
}

.cabin_card .ant-select.form-control.pr-0 {
    padding-right: 0 !important;
}

.unreadCount {
    position: absolute;
    right: 13px;
    top: -7px;
    background: #f9a720;
    color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    font-size: 13px;
    text-align: center;
    line-height: 22px;
    font-weight: 400;
}

.cm_excel_upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.cm_excel_upload {
    position: relative;
}
.signature_pad{border: 1px solid #000;}


.show_on_print {
    display: none;
}

.show_on_print .qr_img {
    max-width: 300px;
    display: inline-block;
    border: 2px solid #d8d6d6;
    margin-right: 20px;
    margin-bottom: 20px;
}

.criteria {
    position: relative;
    font-size: 14px;
    padding-left: 56px;
}

.criteria b {
    font-weight: 400;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.criteria > span {
    background: #e8e8e8;
    margin-right: 10px;
    padding: 0px 5px;
}

@media print {
    body * {
      visibility: hidden;
    }

    .show_on_print {
        display: block;
    }

    .booking_detail *,
    .booking_card_print,
    .booking_card_print *,
    #section-to-print,
    #section-to-print * {
      visibility: visible;
    }

    .booking_card_print,
    #section-to-print {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
    }

    .booking_detail .hide_on_print,
    .booking_card_print .hide_on_print,
    #section-to-print .hide_on_print {
      display: none !important;
    }
  }

.cm_text_area {
    min-height: 300px;
}

.upload_2 {
    width: 100%;
    height: 130px;
    border: 1px dashed #c1c1c1;
}

.trainingGuidePdf {
    border: 1px solid #f1f1f1;
    padding: 10px;
    text-align: left;
    margin-bottom: 15px;
    border-radius: 4px;
    position: relative;
}

.trainingGuidePdf svg {
    width: 22px;
}

.trainingGuidePdf .icon-close {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 20px;
    cursor: pointer;
}

.upload_2.uploaded {
    opacity: 0.4;
    pointer-events: none;
}

.some_right_pos {
    right: 30px !important;
}

.cp_btn {
    font-size: 12px;
    padding: 3px 8px;
}

.cancel_policy ul {
    margin: 0;
    padding-left: 19px;
}

.cancel_policy ul li {
    margin-bottom: 10px;
}

.err_msg {
    color: red;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 14px;
}

label.with_a_link a {
    font-size: 12px;
    display: block;
}

label.with_a_link {
    min-height: 46px;
}

@media (min-width: 1600px){
    .map_modal {
        max-width: calc(100% - 7rem);
    }
}

@media (min-width: 1200px){
    .feed_modal {
        max-width: 1140px;
        margin-top: 161px;
        padding: 0 15px;
    }

    .feed_modal .modal-content {
        max-width: calc(66.666667% - 11px);
        border-radius: 10px;
        border: 0;
    }
}

@media(min-width: 992px){
    .menu_overlay,
    .show_on_mob,
    .menu_trigger {
        display: none;
    }

    .add_prog_modal {
        max-width: 1140px;
    }
}


@media(max-width: 1199px){
    .camp_type_carousel_wrapper .slick-slider {
        padding-top: 50px;
    }

    .camp_type_carousel_wrapper .slick-next, .camp_type_carousel_wrapper .slick-prev {
        top: 0;
    }

    .cm_header ul li {
        margin-left: 7px;
    }

    .contact_bg {
        min-height: 300px;
    }

    .about_img {
        display: none !important;
    }

    .booking_date_numner {
        position: static;
    }

    .booking_btns {
        padding-right: 0;
        min-height: 1px;
        margin-top: 0 !important;
    }
}


@media(max-width: 1024px) {
    .camps_carousel_wrap {
        margin-left: 0;
        margin-right: 0;
    }
}

@media(max-width: 991px){
    header {
        min-height: 106px;
    }

    .cm_blck_bg:after,
    .hide_in_mob,
    .top_bar_left {
        display: none !important;
    }

    .top_bar .justify-content-between {
        justify-content: flex-end !important;
    }

    .cm_header .container {
        position: relative;
        padding-left: 60px;
    }

    .menu_wrapper {
        position: fixed;
        width: 280px;
        height: 100%;
        left: 0px;
        top: 0;
        z-index: 999;
        padding-top: 40px;
        background: #ffffff;
        box-shadow: 3px 0 8px 1px rgba(0,0,0,.16);
        transition: all 0.3s;
        transform: translateX(-100%);
    }

    .menu_open .menu_wrapper {
        transform: translateX(0);
    }

    .menu_overlay {
        position: fixed;
        width: calc(100% - 280px);
        height: 100%;
        right: 0;
        top: 0;
        background: black;
        z-index: 999;
        opacity: 0;
        cursor: e-resize;
        transition: all 0.3s;
        transform: translateX(calc(-100% - 280px));
    }

    .menu_open .menu_overlay {
        transform: translateX(0);
        opacity: 0.4;
    }

    .cm_header ul li {
        display: block;
        margin-left: 0;
    }

    .cm_header ul li a:after {
        display: none;
    }

    .cm_header ul li a {
        padding: 10px 15px;
    }

    .cm_header ul li a:hover {
        color: #fff;
        background: #F9A720;
    }
    
    .menu_trigger {
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
    }
    
    .menu_trigger > span {
        display: inline-block;
        width: 100%;
        position: relative;
        height: 2px;
        background: #000;
    }
    
    .menu_trigger > span:after, .menu_trigger > span:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2.5px;
        background: black;
    }

    .menu_trigger > span:after {
        top: -10px;
    }

    .menu_trigger > span:before {
        top: 10px;
    }

    .menu_open {
        overflow: hidden;
    }

    footer .cm_left_border {
        border-left: 0;
        margin-top: 30px;
    }

    .auth_modal .modal-content {
        min-height: 200px;
    }

    a.show_on_mob {
        color: #842014;
        position: relative;
        top: 6px;
    }

    a.show_on_mob:hover {
        color: #F9A720;
        text-decoration: none;
    }

    .camps_carousel_wrap {
        padding: 40px 50px;
        position: relative;
    }

    .container.off_bg .col-lg-6 {
        margin: 0 !important;
        padding: 15px !important;
        text-align: center;
    }

    .off_img {
        position: static !important;
        display: block;
        margin: 0 auto;
    }

    .map_modal {
        max-width: 100%;
        min-height: 100%;
        margin: 0;
    }

    .cm_map > div {
        height: 100vh !important;
    }

    .cm_calender_flex {
        display: block !important;
    }

    .cm_cal_right {
        width: 100%;
        margin-left: 0;
    }

    .cm_calender_flex .adds_card {
        display: none;
    }

    .cm_cal_left {
        min-height: 350px;
        margin: 0 auto;
    }

    .cm_cal_left .ant-picker {
        width: 1px;
    }

    .activity_btn {
        top: -60px;
    }

    .fc_actions {
        top: -50px;
    }

    .sticky_wrapper .cm_stiky {
        display: none !important;
    }

    .col_lg_8 {
        display: block;
        width: 100%;
        padding-right: 0;
    }

    .downloads_btns .btn {
        margin-bottom: 15px;
    }

    .dir_camp_card {
        display: block !important;
    }

    .cm_mob_center {
        justify-content: center;
    }

    .infos_icons {
        position: static;
    }

    .chcek_icon {
        right: auto;
        left: 0;
    }


}

@media(min-width: 768px){
    .chat_mob_trigger {
        display: none !important;
    }
}

@media(max-width: 767px){
    body {
        font-size: 14px;
    }

    h2, .h2 {
        font-size: 24px;
    }

    h3, .h3 {
        font-size: 20px;
    }

    .main_banner .carousel img {
        min-height: 780px;
    }

    .camp_type_carousel_wrapper {
        padding-top: 40px;
    }

    .camps_carousel_wrap {
        padding: 40px 12px;
    }

    .footer_middle {
        padding: 10px 0;
    }

    .cm_header ul li a {
        font-size: 18px;
    }

    .section_header {
        display: block !important;
        text-align: center;
    }

    .section_header .css-2b097c-container {
        margin-bottom: 15px;
    }

    .no_bg__input input, .no_bg__single-value, .no_bg__placeholder {
        font-size: 24px !important;
    }

    .cat_btn_group {
        white-space: nowrap;
        overflow: auto;
        padding: 10px;
    }

    .camp_card_content {
        position: static;
    }

    .camp_card_content h3 {
        font-size: 20px;
    }

    .camp_card_content .text-right {
        text-align: left !important;
        margin-top: 20px;
    }

    .section_header {
        margin: 0;
        margin-bottom: 15px;
    }

    .map_view_wraper {
        padding-left: 0;
    }

    .map_view_filters {
        left: -250px;
    }

    .program_list {
        left: -280px;
    }

    .cm_open .map_view_filters {
        left: 0;
    }

    .no_img {
        position: static;
        text-align: center;
        padding: 50px 15px;
    }

    .no_img svg {
        position: static;
        transform: none;
    }

    .prog_d {
        display: block !important;
    }

    .prog_media {
        min-height: 200px;
    }

    .prog_d > * {
        margin-bottom: 20px;
    }

    .associated_sm_card {
        padding-left: 10px;
    }

    .associated_sm_card img {
        position: static;
        width: calc(100% - 50px);
        margin-bottom: 10px;
    }

    .associated_wrapper {
        padding: 35px 15px;
    }

    .cm_border_right {
        border-right: 0;
        padding-right: 15px !important;
    }

    .cm_border_right + .pl-5 {
        padding-left: 15px !important;
    }

    .cm_card {
        margin-bottom: 20px;
    }

    .camps_carousel_wrap .slick-next {
        right: -25px;
    }

    .camps_carousel_wrap .slick-prev {
        left: -25px;
        z-index: 999;
    }

    .camp_type_carousel_wrapper .row {
        display: block !important;
        margin: 0 !important;
        width: 100%;
    }

    .camp_type_carousel_wrapper .row .starting_soon_card {
        padding-right: 0 !important;
        margin-bottom: 20px;
    }

    .advertising .cm_card {
        margin-bottom: 20px;
    }

    .career .btn_wrapper {
        width: 100%;
        font-size: 9px;
    }

    .input_explore_btn {
        width: 25%;
    }

    .list_camp_form_wrapper .form-control {
        margin: 0 auto;
    }

    .know_about_you_wrapper img {
        height: 700px;
    }

    .cart_card {
        padding-right: 15px;
    }

    .cart_actions {
        position: static;
        height: auto;
    }

    .cart_actions .text-center,
    .cart_actions .text-right {
        text-align: left !important;
    }

    .d_md_block {
        display: block;
        height: 5px;
    }

    .cart_card p {
        margin-bottom: 5px;
    }

    .card.booking_card {
        padding-left: 15px;
    }

    .card.booking_card img {
        position: static;
        width: 100%;
        height: 210px;
        margin-bottom: 35px;
    }

    .booking_icon_btns {
        right: 15px;
        top: 233px;
    }

    .booking_price {
        position: static;
    }

    .cm_about_booking {
        padding-right: 0;
    }

    .chat_mob_trigger {
        position: absolute;
        left: 15px;
        top: 20px;
        cursor: pointer;
    }

    .program_head h2 {
        font-size: 20px;
    }

    .program_head h2 img {
        display: block;
        margin-bottom: 10px;
    }

    .program_page h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .prog_price {
        position: relative;
    }

    .price_detail_popover {
        position: absolute;
        right: 0;
        top: 2px;
    }

    .cart_price {
        position: relative;
    }

    .card.booking_card .no_img {
        width: 100%;
        margin-bottom: 35px;
    }

    .cm_banner_text p {
        font-size: 15px;
    }

    .cm_banner_text h2 {
        font-size: 35px;
        margin-bottom: 15px;
    }

    .section_title_center, .section_title_left {
        margin-top: 20px;
    }

    .page_card_header {
        display: block !important;
    }

    .btn_group {
        margin-top: 15px;
    }

    .fix_btn {
        min-width: 1px;
    }

    label.with_a_link {
        min-height: 1px;
    }

    .full_img_card {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}

@media(max-width: 620px){
    .map_view_filters {
        width: 160px;
    }

    .program_list {
        width: 160px;
    }

    .program_list {
        left: -160px;
    }

    .cm_open .program_list {
        left: 160px;
    }

    .program_sm_card > img {
        width: 100%;
        height: 100px;
        position: static;
        margin-bottom: 5px;
    }

    .program_sm_card {
        padding-left: 10px;
    }

    .list_camp_form_wrapper .form-control {
        width: 100%;
    }

    .cm_counts li {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #B4B4B4;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .cm_counts li:last-child {
        border: 0;
    }

    .camper_card {
        padding: 15px;
        display: block !important;
        position: relative;
        padding-left: 75px;
    }

    .camper_card img {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .cm_mob_abs2 {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 28px;
    }

    .program_sm_card img {
        width: 100%;
        height: 100px;
        position: static;
    }

    .partner-form.search_wrap {
        max-width: 100%;
    }
}

@media(max-width: 576px) {
    .cart_card > img {
        position: static;
        width: 100%;
    }

    .cart_card {
        padding-left: 15px;
    }

    .cart_card {
        width: calc(100% - 20px);
        margin: 30px auto;
    }

    .cart_actions {
        position: static;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
    }

    .cart_price.text-right {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 140px;
        text-align: right !important;
    }

    .reedem_wrap {
        width: 100%;
        position: static;
        margin-top: 20px;
    }

    .card.coupon_card {
        padding-right: 15px;
    }

    .off_msg {
        background-repeat-x: repeat;
        background-repeat-y: no-repeat;
        background-position: 0% calc(100% + 15px);
        border-radius: 5px 5px 0 0;
    }

    .cart_card > a, .cart_card > .no_img {
        position: static;
    }

    .cart_card h3 {
        font-size: 21px;
        margin-top: 10px;
    }

    .cart_actions {
        display: block !important;
    }

    .cart_price.text-right {
        position: static;
        width: 100%;
    }

    .campers_list > li {
        display: block;
        padding-left: 75px;
        position: relative;
    }

    .campers_list li img {
        position: absolute;
        left: 10px;
        top: 15px;
    }

    .campers_list li p {
        margin: 15px 0;
    }

    .friend_list li > div {
        display: block;
        text-align: right;
    }

    .page_card_header {
        display: block !important;
    }

    .contact_us_wrapper .header h2 span {
        font-size: 25px;
        margin-left: 0;
        display: block;
    }

    .dir_camp_card > div > a,
    .dir_camp_card > div,
    .dir_camp_card {
        display: block !important;
    }

    .dir_camp_card img {
        width: 100%;
        height: 175px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .cm_slots {
        width: 100%;
        text-align: left;
    }

    .cm_mob_left {
        text-align: left !important;
        margin-top: 15px;
    }

    .counselor_card {
        display: block !important;
        padding-left: 75px;
    }

    .counselor_card img {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .folder_icon {
        width: 50px;
        left: 10px;
    }

    .counselor_card .btn-primary {
        font-size: 14px;
        margin-top: 15px;
        margin-right: 10px;
        vertical-align: baseline;
    }

    .folder_model {
        margin: auto;
    }

    .cm_mob_abs {
        position: absolute;
        right: 7px;
        top: 16px;
    }

    .cm_mob_center {
        text-align: center;
        margin: 20px 0;
    }

    .cm_mob_center > div {
        display: inline-block !important;
        width: 100px;
        text-align: center;
    }

    .cm_mob_swich {
        position: absolute;
        right: 0;
        top: -30px;
        margin-right: 0 !important;
    }

    .cm_mob_pad_0 {
        padding-left: 15px !important;
    }

    .top_bar {
        font-size: 12px;
        padding: 13px 0;
    }

    .gift_card_link {
        margin-right: 0px;
    }

    .header_drop button {
        font-size: 12px;
    }

    footer .social_links a {
        margin-left: 10px;
    }

    .copyright_wrapper {
        font-size: 12px;
    }

    .cm_online_store_btn {
        left: 0px;
        right: auto !important;
    }

    .all_actions i {
        margin-left: 8px;
    }

    .no_img_2 {
        width: 100%;
        height: 150px;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .no_img_2 svg {
        width: 100px;
    }
}

@media(max-width: 374px) {
    .input_explore_btn  {
        font-size: 10px ;
    }

    .btn_transp {
        padding: 10px 5px
    }

    .camps_carousel_wrap .slick-prev {
       display: none !important;
    }

    .camps_carousel_wrap .slick-next{
        display: none !important;
    }
}



.add_question_btn {
    text-align: center;
    border: 2px solid #842014;
    width: 208px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    color: #000000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
}

.add_question_btn:hover {
    background: #842014;
    color: #fff;
}

.cm_questions > i {
    position: absolute;
    right: 0;
    top: 8px;
    font-size: 24px;
    color: #842014;
    cursor: pointer;
}

.cm_error {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    color: red;
    font-size: 14px;
}







.cm_waiver ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cm_waiver li svg {
    width: 28px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.cm_waiver li {
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cm_waiver li a {
    color: #2b2b2b;
}

.cm_waiver li a span {
    color: #b53438;
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
}

.cm_waiver li label {
    margin: 0;
}

.last_pdf {
    display: flex;
    width: 80%;
    margin: 0 auto;
}

.last_pdf input {
    display: block;
    width: 100% !important;
}

.last_pdf p:first-child {
    width: 70%;
    margin-right: 24px;
}

.cm_waiver li .form-error {
    position: absolute;
    bottom: -17px;
}

.pdf_box {
    padding: 7%;
    font-size: 14px;
    color: #000;
    width: 100%;
}
.pdf_box h3 {
    font-size: 18px;
}
.pdf_box ol {
    padding-left: 19px;
}
.pdf_box input {
    border: 0;
    border-bottom: 1px solid #000;
    outline: none;
}

.with_logo {
    text-align: center;
    position: relative
}

.with_logo img {
    position: absolute;
    left: 0;
    width: 80px;
    top: -30px;
}

.sign_box {
    display: inline-block;
    vertical-align: text-top;
    border: 1px solid #d9d9d9;
    margin-left: 20px;
    margin-bottom: 10px;
    position: relative;
}

.sign_box_clear {
    position: absolute;
    right: -88px;
    top: -1px;
}

.sign_box {
    position: relative;
}

.sign_box_clear .btn {
    display: block;
    margin-bottom: 8px;
    min-width: 80px;
    padding: 6px;
}

.updated_icon img {
    width: 34px !important;
    height: auto !important;
    margin: 0 !important;
}

.pdf_box_in_mob {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 999;
    overflow: auto;
    padding: 20px;
}

.pdf_box_in_mob .sign_box {
    margin-left: 0 !important;
    max-width: 100%;
    overflow: hidden;
}


.pdf_box_in_mob input {
    width: 100%;
    margin-bottom: 20px;
}

.pdf_box_in_mob .sign_box_clear {
    position: static;
    text-align: center;
}

.pdf_box_in_mob .sign_box_clear button {
    display: inline-block !important;
    margin: 10px !important;
}

label.concussion svg {
    width: 35px;
}

.pdf_box_in_mob .with_logo img {
    position: static;
    margin-bottom: 20px;
}

.concussion {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    margin-bottom: 35px;
}

.concussion img {
    width: 34px;
    height: 27px;
}

.split_price b {
    position: relative;
    color: #c3c3c3;
}

.split_price b:before {
    content: '';
    position: absolute;
    width: calc(100% - 15px);
    height: 2px;
    background: #ff1c01;
    right: -15px;
    top: 12px;
}


button.ant-picker-year-btn,
button.ant-picker-month-btn {
    position: relative;
    padding-right: 15px;
    margin-right: 10px;
}

button.ant-picker-year-btn {
    margin-right: 0 !important;
}

button.ant-picker-year-btn:after,
button.ant-picker-month-btn:after {
    content: '';
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.cm_top_img img {
    max-height: 140px;
    max-width: 100%;
}

.not_found {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 21px;
    margin: 50px 0;
}

.cm_active {
    background-color: #842014;
    border-color: #842014;
    color: #fff;
}

.schedule_wrapper {
    border: 1px solid #f0f0f0;
    padding: 15px;
    width: 1110px;
    overflow: auto;
    position: relative;
}
.schedule_wrapper_container {
    overflow: auto;
}

.calendar_month {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 11px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

.calendar_action {
    position: absolute;
    right: 15px;
    top: 7px;
}

.calendar_action span {
    font-weight: 400;
    font-size: 13px;
    padding: 8px 15px;
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 3px;
    color: #fff;
}

.calendar_action span:hover {
    background: #ffffff;
    color: #000;
}

.ant-picker-calendar-mode-switch {
    display: none !important;
}

.ant-picker-calendar-header {
    justify-content: start;
    background: #842014;
    margin: -15px;
    margin-bottom: 35px;
    padding: 7px 15px;
}

.cal_box {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 7px;
    overflow: auto;
    padding-top: 20px;
}

.cal_box.bg_dark {
    background: #f1f1f1;
    background: #f9f9f9;
}

.ant-picker-calendar-date-value {
    position: relative;
    z-index: 99;
}

.cal_box > div {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: #842014;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-left: 10px;
}

.cal_box > div:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: #b5b5b5;
    border-radius: 100%;
    left: 0px;
    top: 4px;
}

.schedule_modal .modal-header {
    border: 0;
    justify-content: center;
}

.schedule_modal .modal-header h3 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.cm_sch_card {
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 7px;
    margin-bottom: 15px;
    position: relative;
    padding-right: 90px;
}

.cm_sch_card h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.cm_sch_card_time {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0;
}

.cm_sch_action .icon-pancil,
.cm_sch_card .icon-delete {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.cm_sch_action .icon-pancil {
    right: 53px;
}

.cm_sch_card_time span {
    margin-right: 30px;
}

.s_date {
    position: absolute;
    left: 15px;
    top: 11px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
}

.copy_day {
    margin-bottom: 5px;
    display: flex;
}

.copy_day .btn {
    margin-left: 10px;
    padding: 6px 20px;
}

.copy_day .ant-picker {
    min-width: 230px;
}

.cm_sch_card_d {
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0;
}

.cm_sch_card_d > div {
    word-break: break-word;
}


.cm_sch_action .btn {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 4px 10px;
}

.sh_del {
    font-size: 13px;
    padding: 4px 10px;
    margin-bottom: 15px;
}

.hide_space_ .ant-picker-time-panel-column::after {
    height: 0 !important;
}

.cm_commited,
.cm_gold_color {
    color: #edc800 !important;
}

.self_asign {
    color: #f9a720 !important;
    font-weight: bold !important;
}

.cm_stf img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
    display: block;
    margin: 0 auto;
}

.cm_stf_wrapper {
    display: flex;
    margin-top: 15px;
    padding-bottom: 10px;
    overflow: auto;
    width: 100%;
}

.cm_stf {
    margin-right: 20px;
    text-align: center;
    position: relative;
}

.cm_stf a, .cm_stf span {
    color: #000;
    text-decoration: none;
}

.cm_stf small {
    white-space: nowrap;
}

.hide_space_ .ant-picker-footer {
    display: none !important;
}

.cm_stf_delete {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    margin-left: 24px;
    background: #ffffff;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    top: 0px;
    cursor: pointer;
}

.ques_err {
    font-size: 13px;
    color: red;
    margin-top: 8px;
}


.question_types {
    margin-right: 40px;
    display: flex;
    align-items: center;
}

.cm_ques textarea {
    margin-bottom: 25px;
}


.question_types p {
    font-size: 13px;
    white-space: nowrap;
    margin: 0;
    margin-right: 10px;
    color: #842014;
    font-weight: 500;
}

.question_types .cm_swich_wrap {
    margin-right: 40px;
}

.question_types .cm_select__control {
    min-width: 300px;
}

.cm_options li {
    position: relative;
    margin: 10px 0;
}

.cm_options li i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}



.district_camps {
    padding-top: 30px;
}

.district_camps .main_filter {
    background: #00A1C7;
    margin-bottom: 30px;
}

.district_camps .btn-secondary {
    background: #79AD36;
    border-color: #79AD36;
}

.district_camps .cat_bg {
    background: #79AD36;
}

.district_logo img {
    width: 120px;
}

.district_logo {
    text-align: center;
    margin-bottom: 30px;
}

.district_logo_color {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.district_logo_color img {
    width: 200px;
}

.district_slide_wraaper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.district_slide {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
}

.district_slide.active {
    background: #00a1c7;
    border-radius: 10px;
}

.district_slide_img {
    background: white;
    text-align: center;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    padding: 10px;
    border-radius: 10px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: #fff;
}

.district_slide_img img {
    max-height: 130px;
    max-width: 140px;
}

.district_slide h5 {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.district_slide.active h5 {
    color: #fff !important;
}


.district_camps .slick-next:before, .district_camps .slick-prev:before {
    color: #00a1c7;
    font-size: 28px;
}

.invalid-feedback {
    color: red;
    display: block;
    text-align: center;
}

p.res_msg {
    font-size: 13px;
    color: #489400;
}

.res_h6 {
    color: green;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
}

.school_fliter {
    position: relative;
    z-index: 9999;
}

.cm_err {
    color: #dc3545;
    font-size: 14px;
}

.table_responsive_wrap .t_text {
    display: inline-block;
    min-width: 175px;
}

.coupon_actions {
    display: flex;
    min-width: 80px;
    align-items: center;
    justify-content: space-between;
}

.coupon_table_wrapper {
    margin-top: 30px;
    padding: 15px;
}

.mini_loader {
    bottom: 8px;
    width: 33px;
    display: inline-block;
    position: relative;
}

img.us_flag {
    width: 20px;
}

.d_users_list .table_responsive_wrap .t_text {
    min-width: 25px;
}

.cm_steppes_list {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 15px;
}

.cm_steppes_list li {
    margin-bottom: 5px;
    color: #8e8e8e;
    background: #f8f7f7;
    padding: 14px;
    border-radius: 0px;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
}

.cm_steppes_list li.active {
    background: #842015;
    color: #fff;
}

.program_btn_item {
    display: inline-block;
    position: relative;
}

.program_btn_close {
    position: absolute;
    right: 26px;
    top: -12px;
    background: #f7a721;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    line-height: 27px;
    cursor: pointer;
    display: none;
}

.program_btn_group span {
    margin-bottom: 10px;
}

.program_btn_item:hover .program_btn_close {
    display: block;
}

.my_editer .ql-container {
    min-height: 150px;
    max-height: 150px;
    overflow: auto;
}


.cm_child_options_wrapper li {
    display: flex;
}

.cm_child_options_wrapper p {
    margin: 0;
    width: 50%;
}

.cm_child_options_wrapper li .form-group {
    min-width: 22%;
}


.staff_create_link {
    font-size: 13px;
    position: relative;
    top: -15px;
}

.staff_create_link p {
    margin: 0;
}

.staff_create_link span {
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
}

.staff_create_link.copyied span {
    color: #842015;
}

.card.staff_permissions p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.card.staff_permissions p b {
    font-size: 14px;
}


.multi_dates ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.multi_dates {
    padding: 30px 0px;
}

.multi_dates ul li {
    margin-bottom: 12px;
}

.cm_selected_date {
    border: 1px solid #dbdbdb;
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cm_selected_date.active {
    background: #841f15;
    color: #ddd;
    border-color: #832015;
}

.participant_count .cm_selected_date {
    position: relative;
    padding-right: 40px;
}

.participant_count .cm_selected_date b {
    position: absolute;
    right: 0;
    top: 0;
    background: #841f15;
    color: #fff;
    width: 20px;
    height: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
}




.payment-gateway h3 {
    font-size: 24px;
    margin-bottom: 30px;
}

.payment-gateway-card {
    border: 2px solid #e1e1e1;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 6px 6px rgb(0 0 0 / 6%);
    margin-bottom: 30px;
    cursor: pointer;
    min-height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.payment-gateway-card.active {
    border-color: #841f15;
    background: #fff1f0;
}

.payment-gateway-card h4 {
    font-size: 18px;
    font-weight: 500;
}

.payment-gateway-card h2 {
    font-size: 24px;
    font-weight: 500;
}

.payment-gateway-card p {
    margin: 0;
    font-size: 13px;
}


.date_option {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.date_option p {
    margin: 0;
    margin-right: 15px;
    font-size: 21px;
    color: #000;
}

.date_option .check_wrapper {
    margin-left: 30px;
}

.participant_count {
    min-width: 190px;
}

.my_view_all {
    background: #841f15;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.program_date_model h3 {
    font-size: 18px !important;
    margin-bottom: 25px !important;
}

.program_date_model .participant_count {
    text-align: center;
}

.payment-gateway-card .my_tooltip {
    position: absolute;
    width: 18px;
    right: 10px;
    top: 10px;
    height: 18px;
    display: inline-block;
    line-height: 1;
}


.onboarding_form {
    padding: 30px;
}

.owner_remove {
    position: absolute;
    right: 10px;
    top: 45px;
    cursor: pointer;
}

.z_index_top {
    z-index: 999;
}

span.my_tooltip {
    right: 0;
    top: 0;
    margin-left: 15px;
    cursor: pointer;
}

span.my_tooltip img {
    width: 22px;
}

.ql-snow .ql-tooltip.ql-flip {
    transform: translateY(25px) !important;
}

.page_sm_loader svg {
    width: 200px;
}

.page_sm_loader {
    width: 200px;
    margin: 0 auto;
}


.pack_loader {
    position: absolute;
    width: 24px;
    height: 24px;
    right: -6px;
    top: 3px;
    border: 2px solid #000;
    border-radius: 100%;
    border-bottom: 0;
    border-left: 0;
    animation: fillRoted 1s infinite linear;
}

.signature_image {
    object-fit: contain !important;
    border-radius: 0 !important;
    width: auto !important;
    margin: 0 !important;
    max-width: 100%;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    min-width: 200px !important;
}

.d-camper-info {
    word-break: break-word;
    width: calc(100% - 255px);
}

.cm_mob_left {
    min-width: 132px;
}

.cm_options h6 {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 13px;
    font-weight: 400;
    color: red;
    margin: 0;
}

.m-width-camper-name {
    max-width: calc(100% - 140px);
}

.ant-notification {
    z-index: 99999;
}


.cm_multi_selects {
    position: relative;
}

.cm_multi_select_box {
    position: absolute;
    background: #fff;
    z-index: 9;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 4px;
    width: 100%;
    top: 50px;
    left: 0;
    max-height: 250px;
    overflow: auto;
}

.cm_multi_select_box p {
    padding: 5px 15px;
    margin: 0;
}

.cm_multi_selects .form-control {
    cursor: pointer;
    margin: 0;
    line-height: 2;
    white-space: nowrap;
    overflow: auto;
}

.cm_multi_select_box p b {
    font-weight: 500;
    color: #000;
    margin-left: 10px;
}

.cm_multi_selects .form-control span {
    display: inline-block;
    background: #fff;
    margin: 0 3px;
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 4px;
}

.ant-select.form-control.ant-select-multiple {
    height: auto;
    word-break: break-word;
    min-height: 38px;
    padding-top: 5px !important;
}

.user_name {
    display: inline-block;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    position: relative;
    top: 2px;
}

.cart_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #ffffffd4;
    z-index: 99;
    user-select: none;
}

.upload_2 .page_loader {
    border-radius: 10px;
    position: absolute !important;
}

.trainingGuideUploadLoader .page_loader {
    position: absolute !important;
}

.coupon_expired_bg {
    opacity: 0.5;
}

.coupon_expired_bg .off_msg {
    background-color: #b5b5b5 !important;
}

.card_info {
    display: flex;
    align-items: center;
}

.card_info img {
    width: auto;
    border-radius: 0;
    height: auto;
    width: 47px;
    margin: 0 !important;
}

.card_info p {
    margin: 0;
    margin-left: 10px;
}

.pro_wrapper2 i.input_info {
    top: 34px;
    right: 15px;
}

.profile_upload .mini_loader {
    width: 110px;
    top: 0px;
    opacity: 0.4;
    position: absolute;
    left: 0;
}

.hide_on_print .btn {
    white-space: nowrap;
}

.waring_text {
    color: red;
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
}

.res_err {
    color: red;
    font-size: 14px;
}

.gall_popup video {
    max-width: 100%;
}



.cm_online_store_btn {
    position: absolute;
    right: 0;
    top: -46px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 400;
}


.product_card > img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s;
}

.product_card > img:hover {
    opacity: 0.8;
}

.product_card {
    margin-top: 24px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    overflow: hidden;
}

.product_card .slick-dots {
    bottom: -20px;
}

.product_card img {
    border-bottom: 1px solid #d0d0d0;
}

.product_text {
    padding: 10px;
}

.product_text h3 {
    font-size: 15px;
    font-weight: 500;
    min-height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    word-break: break-word;
}

.pro_card_price p {
    margin: 0;
    color: #852114;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.product_actions {
    position: absolute;
    background: #00000047;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.25s;
    transform: scale(0.88);
    opacity: 0;
}


.product_card:hover .product_actions {
    transform: scale(1); 
    opacity: 1;
}

.product_modal h3 {
    font-size: 18px;
}

.product_modal p {
    font-size: 13px;
    line-height: 1.5;
}

.product_price h2 {
    margin: 0;
    font-size: 20px;
    color: #56120a;
}

.icon__cart {
    color: #f9a721 !important;
    font-weight: bold;
}

.upload_wrapper_sm {
    width: 100% !important;
    height: 130px !important;
    border: 1px solid #000;
    margin-top: 0 !important;
}

.input_err_pos .input_info {
    top: 35px;
    height: 38px;
}

.product_card_xl {
    background: #fff;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin-bottom: 30px;
    padding: 15px;
    position: relative;
}

.remove_product_from_cart {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    font-size: 20px;
    z-index: 9;
}

.product_card_xl_content {
    font-size: 13px;
}

.product_card_xl_content p {
    margin-bottom: 10px;
}



ul.product_imgs {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}

ul.product_imgs li {
    display: inline-block;
    width: 90px;
    height: 90px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
}

ul.product_imgs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

ul.product_imgs li i {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}



.by_cancel_camper_list {
    padding-left: 30px !important;
}

.by_cancel_camper_list .check_box_wrapper {
    position: absolute;
    left: 0;
    top: 12px;
}

.product_color_exm {
    position: relative;
    text-transform: capitalize;
    padding-left: 30px;
}

.product_color_exm b {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
    border-radius: 100%;
}

.count_tag .btn {
    position: relative;
}

.count_tag .btn b {
    position: absolute;
    right: 9px;
    top: 7px;
    background: #f9a721;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #fff !important;
    line-height: 24px;
    text-align: center;
    border-radius: 3px;
}

.has_canceled {
    border: 1px solid red;
}

.has_canceled_text {
    position: absolute;
    right: 60px;
    top: 3px;
    z-index: 1;
    font-size: 12px;
    color: red;
    font-weight: 600;
}
.has_canceled_product_text {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
    font-size: 12px;
    color: red;
    font-weight: 600;
}

.has_refunded {
    background: #cdcdcd;
}

.has_refunded .has_canceled_text {
    color: blue;
}
.has_refunded .has_canceled_product_text{
    color: blue;
}

.product_card_2 {
    max-width: calc(100% - 20px) !important;
    margin: 0px auto;
    margin-bottom: 40px;
}

.product_slider .slick-next:before, .product_slider  .slick-prev:before {
    color: #000;
}

.cart_card ul {
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

.pos_r_z {
    z-index: 99;
}


.product_moda .slick-prev,
.product_card .slick-prev {
    left: 0;
    z-index: 9;
}

.product_moda .slick-next,
.product_card .slick-next {
    right: 0;
    z-index: 9;
}

.product_moda .slick-next:before, .product_moda .slick-prev:before,
.product_card .slick-next:before, .product_card .slick-prev:before {
    border-radius: 10px;
    padding-top: 2px;
}

.product_card .slick-next:before, .product_card .slick-prev:before {
    color: #000;
}

.filter_by_ .cm_select__control {
    min-width: 170px;
}

span.book_status {
    font-size: 12px;
    font-weight: bold;
    color: red;
}

.leftItemErr {
    color: red;
    font-size: 12px;
    display: block;
}

.input_space_form2 {
    position: relative;
}

.input_space_form2 .error_tootltip_label {
    top: 0 !important;
    right: 0 !important;
}



.inbox_tabs span {
    display: inline-block;
    padding: 4px 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.inbox_tabs span.active {
    color: #841f15;
    border-bottom: 2px solid #841f15;
}

.me_pro_user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.me_pro_user img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
}

.me_pro_user p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #9b9a99;
}


.chat_tabs {
    margin: 0 -10px;
    padding: 8px 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9b9a99;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

.chat_tabs span {
    position: relative;
    cursor: pointer;
}

.chat_tabs span.active {
    color: #841f15;
}

.chat_tabs span.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #841f15;
    left: 0;
    bottom: -9px;
}

.dir_product_slider {
    max-width: calc(100% - 40px);
    margin: 0 auto;
    text-align: center;
}

.product_text {
    text-align: left;
}


.min_select_h {
    min-height: 165px;
}

.dir_product_slider .product_card {
    overflow: unset;
}

.dir_product_slider .product_card img.img-fluid.pointer {
    border-radius: 9px 10px 0 0;
}

.a_label {
    margin: 0;
    margin-bottom: 10px;
    color: #a6a6a6;
    font-weight: 500;
}

.inbox_editer .ql-container {
    min-height: 200px;
}

@media (min-width: 1400px){
    .container {
        max-width: 1320px;
    }
}


.inbox_data_item {
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin-bottom: 20px;
    padding: 15px;
    position: relative;
    padding-left: 50px;
    display: flex;
    justify-content: space-between;
    padding-right: 150px;
}

.inbox_data_action {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.inbox_data_action > * {
    padding: 4px 10px;
    font-size: 12px;
    margin-left: 10px;
    min-width: 70px;
}

.inbox_data_item h3 {
    font-size: 16px;
    font-weight: 500;
}

.inbox_data_item p {
    font-size: 13px;
}

.inbox_data_item:hover {
    background: #f4f4f4;
}

.inbox_data_item h5 {
    position: absolute;
    left: 15px;
    top: 15px;
    line-height: 1;
    font-size: 24px;
    color: #c7c7c7;
    font-weight: 400;
}

.inbox_data_item_right {
    min-width: 300px;
    margin-left: 25px;
}

.inbox_type {
    display: inline-block;
    padding: 5px 15px;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    margin-right: 10px;
    border-radius: 20px;
    background: #f7f7f7;
}


.chat_view_page {
    padding: 10px;
    border: 1px solid #C7C7C7;
    border-radius: 10px;
    display: flex;
    background-color:#fffaf9;
}

.about_active_chat_user,
.chat_user_list_wrapper {
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    width: 275px;
}
.user_chat_bookings {
    overflow: scroll;
    max-height: calc(100vh - 500px);
}
.scrollable-div {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #841f15 #f1f1f1; /* Thumb color and track color */
  }
  
  .scrollable-div::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .scrollable-div::-webkit-scrollbar-thumb {
    background: #841f15; /* Color of the scroll thumb */
    border-radius: 4px;
  }
  
  .scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb hover color */
  }
  
  .scrollable-div::-webkit-scrollbar-track {
    background: #f1f1f1; /* Scrollbar track color */
  }

.invisible-scrollbar {
    overflow: auto; 
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }
  
.invisible-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.chat_box_layout {
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    margin-bottom: 20px;
    padding: 15px;
    position: relative;
    width: calc(100% - 590px);
    min-height: calc(100vh - 260px);
    margin-left: 20px;
    margin-right: 20px;
}


.chat_user_list_wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -10px;
    margin-right: -10px;
    max-height: calc(100vh - 365px);
    overflow: auto;
}

.chat_user_list_wrapper ul li {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.chat_user_list_wrapper ul li.active {
    background: #FCD19C;
}

.chat_user_list_wrapper p {
    margin: 0;
    font-size: 14px;
    line-height: 1;
}

.chat_user_list_wrapper .chat_user_img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 8px;
}

.about_active_chat_user > img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
    margin-top: 25px;
}

.about_active_chat_user {
    text-align: center;
}

.about_active_chat_user h4 {
    font-size: 16px;
    margin-top: 15px;
    font-weight: 500;
}

.chat_box__header {
    display: flex;
    align-items: center;
    background: #841f15;
    margin: -16px;
    border-radius: 5px 5px 0 0;
    padding: 10px;
    margin-bottom: 0;
    color: #fff;
}

.chat_box__header > img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
    background: #fff;
}

.chat_box__header h4 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 500;
}

.cm_chats_box {
    /* min-height: calc(100vh - 460px); */
    min-height: calc(100vh - 576px);
    max-height: calc(100vh - 576px);
    overflow: auto;
    margin-left: -15px;
    margin-right: -15px;
}

.send_test {
    border: 2px dashed #d2d2d2;
    margin-top: 30px;
    padding: 20px;
    padding-top: 20px;
    border-radius: 10px;
    background: #f7f7f7;
}

.triggers_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.triggers_list li {
    margin-bottom: 11px;
}

.triggers_list .check_box > span {
    border: 2px solid #9b9b9b;
}

.attachments_list {
    margin-bottom: 10px;
}
.attachments_list a {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-bottom: 10px;
    padding-right: 50px;  
}

.attachments_list a svg {
    width: 22px;
    margin-right: 10px;
}

.sport_international .main_filter {
    background: #004b80;
}

.sport_international .btn-secondary {
    background-color: #ed1a3c;
    border-color: #ed1a3c;
}

.premier_partners a {
    margin-right: 30px;
}

.premier_partners a img {
    height: 80px;
}

a.providesupport {
    position: fixed;
    right: 0;
    bottom: 39px;
    z-index: 9;
}

.typing_wrapper_inner textarea {
    min-height: 75px;
    border: 0;
    background: transparent;
    border-radius: 10px;
    box-shadow: none;
}

.typing_wrapper_inner {
    border: 1px solid #888888;
    border-radius: 10px;
}

.typing_wrapper .cm_select__control {
    min-width: 200px;
}

.triggers_list .check_box_wrapper input {
    width: 30px;
}

input.cn_input_num {
    position: static;
    opacity: 1;
    display: inline-block;
    width: 55px !important;
    text-align: center;
    outline: none;
    border: 0;
    border-bottom: 1px solid #cccccc;
    margin: 0 10px;
    line-height: 1;
    padding: 0;
    cursor: auto;
}

.drop_with_img {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.drop_with_img img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 8px;
}

.drop_with_img small {
    margin-left: 10px;
}


.chat_user_list_wrapper small {
    font-size: 11px;
    color: #b3b3b3;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}


@media(max-width: 1399px){
    .chat_box_layout {
        width: calc(100% - 295px);
        margin-right: 0;
    }

    .about_active_chat_user {
        display: none;
    }
}

.ql-editor {
    font-size: 15px;
}

.p_name {
    display: inline-block;
    margin-left: 10px;
    background: #f4f4f4;
    padding: 3px 10px;
    border-radius: 4px;
}

ul.my_nots {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.my_nots li {
    border: 1px solid #e5e5e5;
    padding: 10px 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    position: relative;
    padding-right: 90px;
}

.note_actions i {
    cursor: pointer;
}

.note_actions i:hover {
    color: #56120a;
}

ul.my_nots li small {
    position: relative;
    left: -15px;
    color: #adadad;
}

.note_actions {
    position: absolute;
    right: 20px;
    top: 10px;
    display: flex;
    justify-content: space-between;
    width: 50px;
}

.chat_box_layout .inbox_editer .ql-container {
    min-height: 100px;
}

.chat_box_layout .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid #ccc;
}

.chat_box_layout .ql-container.ql-snow {
    border: 0;
}

ul.user_timeline {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.user_timeline > li {
    display: flex;
    margin-bottom: 30px;
    position: relative;
}

.timeline_inner {
    background: #f9f9f9;

    border-radius: 10px;
    padding: 20px;
    width: calc(100% - 150px);
}
.not_canceled{
    border: 1px solid #dde2e5;
}
.is_canceled {
    border: 1px solid red;
}
.has_canceled_program_text {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 18px;
    color: red;
    font-weight: 600;
}

ul.user_timeline > li time {
    width: 150px;
}

ul.user_timeline > li:before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100%);
    left: 7px;
    top: 28px;
    background: #b6b6b6;
}

.timeline_inner h3 {
    font-size: 21px;
}

.time_line_programs h5 {
    font-weight: 500;
    margin-right: 15px;
    font-size: 18px;
    color: #8f8989;
}

.time_line_programs {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.m_box {
    padding: 5px 15px;
    background: #eeeeee;
    border: 1px solid #d2d2d2;
    color: #000;
    border-radius: 4px;
    margin-right: 10px;
}

.timeline_inner h2 {
    font-size: 24px;
    margin: 0;
    margin-top: 20px;
    color: #832015;
}

.histroy_type {
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.error_feild .quill.inbox_editer {
    border: 1px solid red;
}

.Key_drom_list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 400px;
    margin-bottom: 50px;
}

.Key_drom_list li {
    display: inline-block;
    min-width: 200px;
    font-weight: 500;
    font-size: 12px;
}

.cm_questions2 .question_types {
    margin-left: 30px;
}

.cm_questions2 .question_types .cm_swich_wrap {
    margin-right: 20px;
}

.check_box_wrapper.cm_dark_check_2 {
    position: relative !important;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 100%;
}

.yellow_box {
    padding: 20px;
    background: #fffde5;
    border: 1px solid #233b87;
    color: #233b87;
    margin-bottom: 30px;

}

.waiverType4 h2 {
    font-weight: 400;
    margin-bottom: 20px;
}

.waiverType4 h3 {
    font-weight: 400;
    color: #d97727;
    margin-top: 20px;
    margin-bottom: 20px;
}

.waiverType4_table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 70px;
}

.waiverType4_table ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 50%;
    vertical-align: top;
}

.waiverType4_table ul li {
    font-size: 12px;
    padding: 5px;
    padding-left: 40px;
}

.waiverType4_table ul li:nth-child(odd) {
    background: #f7ddc7;
} 

.waiverType4_table ul:first-child li {
    border-right: 1px solid #d97727
}


.waiverType4_table ul li:first-child {
    background: #d97727;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.waiverType4_table ul:first-child li:first-child {
    border-right: 1px solid #fff
}

.product_wide_card_img img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.p_select_page .cm_err {
    font-weight: 500;
    padding-top: 15px;
}

.booking_users2 {
    padding: 10px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 4px;
    border: 1px solid #d8d6d6;
}

.get_forms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 15px;
}

.embed_div embed{
    min-height: calc(100vh - 160px);
}

.ans_chip {
    display: inline-block;
    background: #f4f4f4;
    margin-right: 10px;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
}

.isCommuter_wrapper {
    background: #efefef;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.isCommuter_wrapper p {
    margin-bottom: 10px;
}

.isCommuter_wrapper .check_box_wrapper {
    margin-bottom: 10px;
    margin-top: 20px;
}
input.mm_file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 9;
    font-size: 0;
    opacity: 0;
}

.waiver_disclaimer {
    border: 1px solid #000000;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 30px;
}

.waiver_disclaimer p {
    margin: 0;
}

.updated_icon {
    cursor: pointer;
}

@media(min-width: 768px){
    .for_mob_p_img,
    .prog_media.prog_media_mob {
        display: none;
    }
}

@media(max-width: 767px){
    .for_desk_p_img,
    .prog_media.prog_media_desk {
        display: none;
    }

    .prog_slide_img img,
    .camp_card>img {
        height: auto !important;
    }
}

.pending_payment_card {
    border: 0;
    box-shadow: 0 3px 20px rgba(0,0,0,0.05);
    padding: 15px;
    border-radius: 10px;
    position: relative;
    background: #fff;
    margin-bottom: 30px;
}

.pp_names {
    display: inline-block;
    padding: 4px 15px;
    background: #dddddd;
    color: #000;
    border-radius: 3px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 10px;
}

._doller_icon svg {
    width: 20px;
    height: 20px;
}


._doller_icon:hover svg path {
    fill: #1890ff;
}


.ant-menu-item-selected._doller_icon:hover svg path,
.ant-menu-item-selected._doller_icon svg path {
    fill: #842014;
}


.p_arr {
    padding: 10px;
    background: #f2f2f2;
    margin-bottom: 15px;
}

.p_arr p {
    margin: 10px 0;
}

.update_sizes {
    background: #efefef;
    padding: 50px 20px;
}

.camper_name {
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    padding: 3px 10px;
    background: #e4e4e4;
    margin-right: 5px;
    border-radius: 3px;
}

.with_auto_hight .cm_select__value-container {
    flex-wrap: wrap !important;
}

.with_auto_hight {
    height: auto;
}

.modal-body .emi_chip {
    background: #eaeaea;
    padding: 10px 30px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 6px;
}
.modal-body .emi_chip.active {
    background: #841f15;
   color: #fff;
}

.notes_count {
    position: relative;
    display: inline-block;
    padding-right: 24px !important;
}

.notes_count b {
    position: absolute;
    right: -11px;
    top: -1px;
    background: #f6a722;
    color: #fff;
    width: 30px;
    height: 24px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    line-height: 24px;
}


.table_for_coupons::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
  
.table_for_coupons::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.coupons_list {
    width: 310px;
    margin-right: 20px;
}

.booking_ans {
    position: relative;
    padding-right: 34px;
}

.booking_ans i {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}
.pp_img {
    height: auto !important;
    border-radius: 0 !important;
}

.p_sub_cat {
    margin-bottom: 20px;
    position: relative;
    padding-right: 40px;
}

.p_sub_cat i {
    position: absolute;
    right: 7px;
    top: 9px;
    cursor: pointer;
}

.product_cat_item {
    display: inline-block;
    padding: 5px 20px;
    background: #e7e7e7;
    margin-right: 22px;
    margin-top: 20px;
    font-size: 14px;
    position: relative;
    z-index: 99;
    cursor: pointer;

}

.product_cat_item ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    background: red;
    min-width: 170px;
}



.product_cat_item ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    background: #e7e7e7;
    min-width: 170px;
    border: 1px solid #c2c0c0;
    top: 33px;
    left: 0;
    display: none;
    z-index: 999;
}

.product_cat_item ul li {
    padding: 10px;
}

.has_child_cat:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
    top: 11px;
    right: 12px;
}

.has_child_cat {
    padding-right: 33px;
}

.has_child_cat:hover ul {
    display: block;
}

.product_cat_item.active {
    background: #841f15;
}
.product_cat_item.active span {
    color: #fff;
}
.product_cat_item.active:after {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

.product_cat_item ul li:hover, .product_cat_item ul li.active{
    background: #841f15;
    color: #fff;
}

.p_filter_by_camp {
    min-width: 290px;
}


.pro_card_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p_cat_text {
    font-size: 12px;
    background: #f9a721;
    color: #fff;
    padding: 1px 10px;
    border-radius: 20px;
}

.product_cat_item i {
    position: absolute;
    right: -9px;
    top: -12px;
}

.product_camp_item {
    display: inline-block;
    margin-right: 22px;
    border: 1px solid black;
    padding: 5px 20px;
    font-size: 13px;
    margin-top: 15px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
}

.product_camp_item.active {
    background: #841f14;
    color: #fff;
}

.react-numeric-input b {
    display: none !important;
}

.react-numeric-input {
    position: relative;
}

.react-numeric-input input {
    padding-left: 20px;
}

.react-numeric-input:before {content: '$';position: absolute;left: 11px;top: 5px;}

.multi_dates.hide_on_desktop {
    padding-bottom: 1px !important;
}

@media(min-width: 992px){
    .multi_dates.hide_on_desktop {
        display: none !important;
    }
}

@media(max-width: 991px){
    .multi_dates.hide_on_mobile {
        display: none !important;
    }
}


.camp_related_product {
    border: 1px solid #dee1e5;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background: #f1f1f1;
}

.user_product_price {
    padding: 10px;
    padding-top: 0px;
}

.user_product_price h6 {
    color: #841f15;
}

.p_cancelled {
    color: #841f15;
    margin: 0;
    font-size: 16px;
}

.user_product_price {
    min-height: 121px;
}

._note p {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

._note {
    position: relative;
}

._note_plus {
    color: green;
}

._note_dic {
    color: red;
}
.select_frequecy {
    margin-left:5px!important;
    margin-right:5px!important;
}
.select_frequecy_dropdown { 
    display: inline-block;
     width: 130px;
}
.approve_switch {
    display: flex;
    justify-content:flex-end;
    margin-right:20px;
}

.camp_contact {
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.camp_contact_text {
    display: inline-block;
    min-width: 250px;
}

.primary-card-text{ 
    padding-right:5px;
    padding-left:5px;
    border-radius: 5px;
    margin-right:15px;
}
.primary-card-content{  
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



@media(max-width: 1400px){
    .for_font_size_sm {
        font-size: 11px;
    }

    .top_bar_left a {
        margin-right: 5px;
    }

    .gift_card_link {
        margin-right: 10px;
    }
}


@media(max-width: 1200px){
    .cm_d_for_mobile {
        display: block !important;
    }

    .top_bar_left {
        justify-content: space-between;
    }

    .for_font_size_sm {
        font-size: 13px;
    }

    .top_bar_right {
        justify-content: end;
        margin-top: 25px;
    }

    .cm_header ul li a {
        font-size: 14px;
    }

    .top_bar_left a {
        margin-right: 15px;
    }
}


@media(max-width: 991px){
    .top_bar_right {
        margin-top: 0;
    }
}


.d_prodcut_list {
    max-height: calc(100vh - 150px);
    overflow: auto;
}

.cm_Checkout_btn {
    position: absolute;
    left: 170px;
    top: 14px;
}

.receipt_wrap {
    border: 1px solid;
    padding: 20px;
    margin-top: 20px;
}

.receipt_wrap h3 {
    font-size: 20px;
}

@media(max-width: 450px){
    .primary-card-text{ 
        font-size: 13px;
        border-radius: 5px;
        margin-right:10px;
    }
    .p_card_img img {
        max-width: 52px;
        height: 37px;
        margin-right: 17.5px;
        margin-left: 0;
    }
}
    

.color_black i {
    font-size: 12px;
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 12px;
    cursor: pointer;
    color: #842014;
}

.age_g_days  span {
    display: inline-block;
    padding: 10px 15px;
    background: #d8d6d6;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 14px;
    min-width: 400px;
}

.in_booking_detail span {
    min-width: 1px !important;
    width: 100%;
}

:root {
    --vi-tag-color: #842014;
    --vi-accept-color: #842014;
    --vi-success-color: #F9A720;
}

multi-offer {
    max-width: 950px;
}

.cm_logo_large img {
    width: 100%;
    max-width: 550px;
    margin-bottom: 50px;
    height: auto; 
}

.dir_booking_list{
    overflow-y: "scroll";
    border-radius: 8px;
    padding: 10px;
    background-color: "#f9f9f9";
}

@media (max-width: 768px) {
    .cm_logo_large img {
        max-width: 400px; 
        margin-bottom: 30px; 
    }
}

@media (max-width: 480px) {
    .cm_logo_large img {
        max-width: 250px; 
        margin-bottom: 20px; 
    }
    .camper-card-font {
        font-size: 12px;
    }
    .has_canceled_text {
        position: absolute;
        right: 5px;
        top: 2px;
        z-index: 1;
        font-size: 7px;
        color: red;
        font-weight: 300;
    }
   
}

@media(max-width:300px){
    .hide-image {
        display: none;
    }
    .camper_card {
        padding-left: 15px;
    }
}

  
.circle-indicator {
    width: 20px; /* Adjust size as needed */
    height: 20px;
    background-color: #dc3545; /* Adjust color as needed */
    border-radius: 50%;
    position: relative;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.6;
    }
}

.animated-text {
    display: inline-block;
    animation: pulse 2s ease-in-out infinite alternate;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }